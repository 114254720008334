import React from "react";

function Reviews() {
    return (
        <section className="reviews">
            <div className="w-layout-blockcontainer container w-container">
                <div className="sub-heading">
                    <div>
                        <div className="sub-title space">
                            <img
                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                                loading="lazy" alt="Title Icon"/>
                            <div>Testimonials</div>
                        </div>
                        <h2 className="section-heading">Customers Reviews</h2>
                    </div>
                    <div className="section-button desktop">
                        <a data-w-id="bfedf59a-f0ed-8cd8-afdb-920d7627be54" href="/reviews"
                           className="button w-inline-block">
                            <div className="secondary-button">
                                <div>View All Reviews</div>
                            </div>
                            <div className="button-arrow">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                    loading="lazy" alt="Arrow" className="hover-arrow"/>
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                    loading="lazy" alt="Arrow" className="arrow"/>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="review-wrap">
                    <div data-w-id="bfedf59a-f0ed-8cd8-afdb-920d7627be5c" className="testimonial-img">
                        <img
                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665e95f461757bfcb72bc4dd_testimonial.jpg"
                            loading="lazy"
                            sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 31vw, 29vw"
                            srcSet="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665e95f461757bfcb72bc4dd_testimonial-p-500.jpg 500w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665e95f461757bfcb72bc4dd_testimonial.jpg 784w"
                            alt="Testimonial Image" className="testimonial-image"/>
                        <div className="on-scroll"></div>
                    </div>
                    <div data-delay="4000" data-animation="slide" className="review-slider w-slider"
                         data-autoplay="false" data-easing="ease" data-hide-arrows="false"
                         data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3"
                         data-duration="500" data-infinite="true">
                        <div className="w-slider-mask">
                            <div className="w-slide">
                                <div className="testimonial-block">
                                    <p className="testimonial-text">Ac porttitor gravida non commodo etiam
                                        ornare. Eget feugiat sed ullamcorper vestibulum non pellentesque nisl
                                        mattis. Odio turpis donec praesent tellus amet viverra nec mattis amet.
                                        Urna ut sit sed molestie enim. Facilisis luctus pulvinar ut fermentum
                                        neque consequat. Molestie porttitor.</p>
                                    <div className="review-bottom">
                                        <div className="review-img">
                                            <img
                                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665e980adbd4aea5f77fd488_review-thumb-01.jpg"
                                                loading="lazy" alt="Review Image" className="review-image"/>
                                        </div>
                                        <div>
                                            <div className="review-name">Courtney Henry</div>
                                            <div>Florida, US</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-slide">
                                <div className="testimonial-block">
                                    <p className="testimonial-text">Sed hendrerit mauris rhoncus elementum dui
                                        quam id. Quis donec euismod sollicitudin libero. Et quis non elit at
                                        ligula purus cursus. Eget ipsum semper metus aliquam facilisis volutpat.
                                        Gravida quisque nullam in nibh. Nunc ac integer nibh nunc mattis
                                        facilisis at.Egestas in amet tincidunt ornare elit.</p>
                                    <div className="review-bottom">
                                        <div className="review-img">
                                            <img
                                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665e9859532d03a8f0c54a29_review-thumb-02.jpg"
                                                loading="lazy" alt="Review Image" className="review-image"/>
                                        </div>
                                        <div>
                                            <div className="review-name">Wade Warren</div>
                                            <div>Texas, US</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-slide">
                                <div className="testimonial-block">
                                    <p className="testimonial-text">Adipiscing maecenas risus aenean tristique
                                        eu libero lorem. Purus elementum pharetra cursus risus magna id. Ornare
                                        lacus turpis ultrices donec scelerisque. Etiam consectetur adipiscing
                                        molestie massa eget varius egestas vulputate sagittis. Tortor id viverra
                                        pharetra nec consectetur non.</p>
                                    <div className="review-bottom">
                                        <div className="review-img">
                                            <img
                                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665e98594f1d47db3f2c29b9_review-thumb-03.jpg"
                                                loading="lazy" alt="Review Image" className="review-image"/>
                                        </div>
                                        <div>
                                            <div className="review-name">Kristin Watson</div>
                                            <div>California, USA</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-slide">
                                <div className="testimonial-block">
                                    <p className="testimonial-text">Porttitor nec ante rhoncus sed sed pharetra.
                                        Tellus natoque ac eget pretium. Accumsan in orci ultricies amet duis
                                        vel. Diam ac amet cursus proin id pellentesque enim pellentesque. Ac
                                        eleifend orci quisque donec suspendisse adipiscing sed consequat. Arcu
                                        lectus viverra varius ac convallis tristique.</p>
                                    <div className="review-bottom">
                                        <div className="review-img">
                                            <img
                                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665e985901e2ba8adc4e7930_review-thumb-04.jpg"
                                                loading="lazy" alt="Review Image" className="review-image"/>
                                        </div>
                                        <div>
                                            <div className="review-name">Devon Lane</div>
                                            <div>Grorgia, US</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-slide">
                                <div className="testimonial-block">
                                    <p className="testimonial-text">Habitasse enim iaculis massa mattis egestas
                                        id odio velit nsectetur. Pharetra mauris platea viverra blandit integer
                                        lacinia. Lorem dolor in diam nibh. Massa etiam ut vel imperdiet nibh.
                                        Arcu in in praesent feugiat morbi pellentesque. Leo nulla tincidunt sit
                                        habitant. Neque eros diam congue adipiscing.</p>
                                    <div className="review-bottom">
                                        <div className="review-img">
                                            <img
                                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665e9859b401a80d510d30b3_review-thumb-05.jpg"
                                                loading="lazy" alt="Review Image" className="review-image"/>
                                        </div>
                                        <div>
                                            <div className="review-name">Albert Flores</div>
                                            <div>Toronto, US</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-w-id="bfedf59a-f0ed-8cd8-afdb-920d7627be9c"
                             className="left-arrow w-slider-arrow-left">
                            <img
                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbb5e145aefab24965c13_impact-left-arrow.svg"
                                loading="lazy" alt="Arrow" className="arrow"/>
                        </div>
                        <div data-w-id="bfedf59a-f0ed-8cd8-afdb-920d7627be9e"
                             className="right-arrow w-slider-arrow-right">
                            <img
                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665db78ae40c823a40cc84f4_impact-r-arrow.svg"
                                loading="lazy" alt="Arrow" className="arrow"/>
                        </div>
                        <div className="d-none w-slider-nav w-round w-num"></div>
                    </div>
                </div>
                <div className="section-button mobile">
                    <a data-w-id="bfedf59a-f0ed-8cd8-afdb-920d7627bea2" href="/reviews"
                       className="button w-inline-block">
                        <div className="secondary-button">
                            <div>View All Reviews</div>
                        </div>
                        <div className="button-arrow">
                            <img
                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                loading="lazy" alt="Arrow" className="hover-arrow"/>
                            <img
                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                loading="lazy" alt="Arrow" className="arrow"/>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Reviews