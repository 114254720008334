import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {Button} from "../ui/Button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../ui/Form";
import {z} from "zod"
import {Check, ChevronsUpDown, Pencil} from "lucide-react";
import {cn} from "../../lib/utils";
import {db} from "../../firebase";
import {Input} from "../ui/Input";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/Popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/Command";
import {categories} from "../../lib/constant";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/Select";

const formSchema = z.object({
    category: z.string().min(1, {
        message: "Category is required"
    }),
});

export const CategoryForm = ({initialData, salonId}) => {
    const [isEditing, setIsEditing] = useState(false);

    const toggleEdit = () => setIsEditing((current) => !current);

    const router = useNavigate();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            category: initialData?.category || ""
        },
    });

    const { isSubmitting, isValid } = form.formState;

    const onSubmit = async (values) => {
        try {
            db.ref('salons').child(`/${salonId}`).update({
                category: values.category
            }).then(() => {
                toast.success("Salon category updated")
                toggleEdit()
                // router(0)
            }).catch((err) => {
                toast.error(err.message)
            })
        } catch {
            toast.error("Something went wrong");
        }
    }

    return (
        <div className="mt-6 border bg-slate-100 rounded-md p-4">
            <div className="font-medium flex items-center justify-between">
                Salon category
                <Button onClick={toggleEdit} variant="ghost">
                    {isEditing ? (
                        <>Cancel</>
                    ) : (
                        <>
                            <Pencil className="h-4 w-4 mr-2" />
                            Edit category
                        </>
                    )}
                </Button>
            </div>
            {!isEditing && (
                <>
                    {categories.filter((search) => search.value === initialData.category).map((item) => (
                        <p className="text-sm mt-2">
                            {item.label}
                        </p>
                    ))}
                </>
            )}
            {isEditing && (
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                        <FormField control={form.control} name="category" render={({ field }) => (
                            <FormItem>
                                {/*<FormControl>*/}
                                {/*    /!*<Input disabled={isSubmitting} placeholder="e.g. 'Finace'" {...field} />*!/*/}
                                {/*</FormControl>*/}
                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select salon category" {...field} />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        {categories.map((category) => (
                                            <SelectItem value={category.value} className="cursor-pointer">
                                                {category.label}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <FormMessage />
                            </FormItem>
                        )} />
                        <div className="flex items-center gap-x-2">
                            <Button disabled={!isValid || isSubmitting} type="submit">
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            )}
        </div>
    )
}