import React, {useState} from "react";
import {z} from "zod"
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {Loader2, PlusCircle} from "lucide-react";
import {Button} from "../ui/Button";
import {Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage} from "../ui/Form";
import {Input} from "../ui/Input";
import {cn} from "../../lib/utils";
import {ServicesList} from "../ServicesList";
import {db} from "../../firebase";
import {services} from "../../lib/constant";
import {Checkbox} from "../ui/Checkbox";

const formSchema = z.object({
    title: z.string().min(1),
    items: z.array(z.string()).refine((value) => value.some((item) => item), {
        message: "You have to select at least one item.",
    }),
});

export const ServicesForm = ({initialData, salonId}) => {
    const [isCreating, setIsCreating] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const toggleCreating = () => {
        setIsCreating((current) => !current);
    }

    const router = useNavigate();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: "",
            items: ["recents", "home"],
        },
    });

    const { isSubmitting, isValid } = form.formState;

    const onSubmit = async (values) => {
        try {
            const postId = db.ref().push().key

            db.ref('salons').child(`/${salonId}/extraServices/${postId}`).update({
                title: values.title,
                serviceId: postId,
                // isPublished: false
            }).then((docRef) => {
                toast.success("Extra service created");
                toggleCreating();
                // router(0);
            }).catch((err) => {
                toast.error(err.message)
            })
        } catch {
            toast.error("Something went wrong");
        }
    }

    const onReorder = async (updateData) => {
        try {
            setIsUpdating(true);

            // await axios.put(`/api/courses/${salonId}/chapters/reorder`, {
            //     list: updateData
            // });
            toast.success("Chapters reordered");
            router.refresh();
        } catch {
            toast.error("Something went wrong");
        } finally {
            setIsUpdating(false);
        }
    }

    const onEdit = (id) => {
        router(`/teacher/courses/${salonId}/chapters/${id}`);
    }

    return (
        <div className="relative mt-6 border bg-slate-100 rounded-md p-4">
            {isUpdating && (
                <div className="absolute h-full w-full bg-slate-500/20 top-0 right-0 rounded-m flex items-center justify-center">
                    <Loader2 className="animate-spin h-6 w-6 text-sky-700" />
                </div>
            )}
            <div className="font-medium flex items-center justify-between">
                Extra services
                <Button onClick={toggleCreating} variant="ghost">
                    {isCreating ? (
                        <>Cancel</>
                    ) : (
                        <>
                            <PlusCircle className="h-4 w-4 mr-2" />
                            Add a service
                        </>
                    )}
                </Button>
            </div>
            {isCreating && (
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4 mt-4"
                    >
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            disabled={isSubmitting}
                                            placeholder="e.g. 'Free parking, Free WiFi, Luxury environment'"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {/*<FormField*/}
                        {/*    control={form.control}*/}
                        {/*    name="items"*/}
                        {/*    render={() => (*/}
                        {/*        <FormItem>*/}
                        {/*            <div className="mb-4">*/}
                        {/*                <FormLabel className="text-base">Sidebar</FormLabel>*/}
                        {/*                <FormDescription>*/}
                        {/*                    Select the items you want to display in the sidebar.*/}
                        {/*                </FormDescription>*/}
                        {/*            </div>*/}
                        {/*            {services.map((item) => (*/}
                        {/*                <FormField*/}
                        {/*                    key={item.id}*/}
                        {/*                    control={form.control}*/}
                        {/*                    name="items"*/}
                        {/*                    render={({ field }) => {*/}
                        {/*                        return (*/}
                        {/*                            <FormItem key={item.id}*/}
                        {/*                                className="flex flex-row items-center space-x-3 space-y-0">*/}
                        {/*                                <FormControl>*/}
                        {/*                                    <Checkbox*/}
                        {/*                                        checked={field.value?.includes(item.id)}*/}
                        {/*                                        onCheckedChange={(checked) => {*/}
                        {/*                                            return checked*/}
                        {/*                                                ? field.onChange([...field?.value, item.id])*/}
                        {/*                                                : field.onChange(*/}
                        {/*                                                    field?.value?.filter(*/}
                        {/*                                                        (value) => value !== item.id*/}
                        {/*                                                    )*/}
                        {/*                                                )*/}
                        {/*                                        }}*/}
                        {/*                                    />*/}
                        {/*                                </FormControl>*/}
                        {/*                                <p className="text-black">*/}
                        {/*                                    {item.label}*/}
                        {/*                                </p>*/}
                        {/*                            </FormItem>*/}
                        {/*                        )*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            ))}*/}
                        {/*            <FormMessage />*/}
                        {/*        </FormItem>*/}
                        {/*    )}*/}
                        {/*/>*/}
                        <Button
                            disabled={!isValid || isSubmitting}
                            type="submit"
                        >
                            Create
                        </Button>
                    </form>
                </Form>
            )}
            {!isCreating && (
                <div className={cn(
                    "text-sm mt-2",
                    !initialData?.extraServices?.length && "text-slate-500 italic"
                )}>
                    {!initialData?.extraServices && "No services"}
                    <ServicesList
                        onEdit={onEdit}
                        onReorder={onReorder}
                        items={initialData?.extraServices || []}
                    />
                </div>
            )}
            {!isCreating && (
                <p className="text-xs text-muted-foreground mt-4">
                    Drag and drop to reorder the chapters
                </p>
            )}
        </div>
    )
}