import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {Button} from "../ui/Button";
import {Pencil} from "lucide-react";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../ui/Form";
import {Input} from "../ui/Input";
import {z} from "zod";
import {auth, db} from "../../firebase";

const formSchema = z.object({
    salonName: z.string().min(1, {
        message: "Salon name is required"
    })
})

export const TitleForm = ({ initialData, salonId }) => {
    const navigate = useNavigate()

    const [isEditing, setIsEditing] = useState(false)
    const toggleEdit = () => setIsEditing((current) => !current)

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: initialData.salonName
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values) => {
        const postId = db.ref().push().key

        try {
            db.ref('salons').child(`/${postId}`).update({
                salonName: values.salonName,
                salonId: postId,
                isPublished: false
            }).then(() => {
                toast.success("Salon updated")
                toggleEdit()
                // navigate(0)
            }).catch((err) => {
                toast.error(err.message)
            })
            // await axios.patch(`/api/courses/${salonId}`, values)
            // router.refresh()

        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <div className="mt-6 bg-slate-100 rounded-md p-4">
            <div className="font-medium flex items-center justify-between">
                Salon name
                <Button variant="ghost" onClick={toggleEdit}>
                    {isEditing ? (<>Cancel</>) :
                        (
                            <>
                                <Pencil className="h-4 w-4 mr-2" />
                                Edit name
                            </>
                        )
                    }
                </Button>
            </div>
            {!isEditing && (
                <p className="text-sm mt-2">
                    {initialData.salonName}
                </p>
            )}
            {isEditing && (
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                        <FormField control={form.control} name="salonName" render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input disabled={isSubmitting} placeholder="e.g. 'Frank Beauty Salon'" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )} />
                        <div className="flex items-center gap-x-2">
                            <Button disabled={!isValid || isSubmitting} type="submit">
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            )}
        </div>
    )
}