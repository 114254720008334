import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {Button} from "../ui/Button";
import {ImageIcon, Pencil, PlusCircle} from "lucide-react";
import {z} from "zod"
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {db, storage} from "../../firebase";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../ui/Form";
import {uploadBytesResumable, ref as stRef, getDownloadURL} from "firebase/storage";
import FileUpload from "../FileUpload";
import {ImageList} from "../ImageList";

const formSchema = z.object({
    // images: z.array(z.string()).min(1, {
    //     message: "Image(s) are required."
    // })
    images: z.array(z.any())
});

export const ImageForm = ({initialData, salonId}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState("")
    const ref = useRef()
    const [image, setImage] = useState(initialData.images)
    const [files, setFiles] = useState([])

    const toggleEdit = () => setIsEditing((current) => !current);

    useEffect(() => {
        // create the preview
        const binaryData = [];
        binaryData.push(image);
        const objectUrl =  window.URL.createObjectURL(new Blob(binaryData))
        setSelectedFile(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [image])

    const router = useNavigate();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            images: initialData?.images || []
        }
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values, e) => {
        values.images.map((item) => {
            console.log(item.file.name)
        })
        values.images.map((item, index) => {
            try {
                const postId = db.ref().push().key

                const storageRef = stRef(storage, `/salons/${salonId}/thumbnail/${item.file.name}`)
                const uploadTask = uploadBytesResumable(storageRef, item.file)

                uploadTask.on("state_changed", snapshot => {
                    const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    // setPercent(percent)

                }, (error) => {
                    toast.error(error.message)

                }, () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        db.ref('salons').child(`/${salonId}/images/${postId}`).update({
                            imageUrl: url,
                            imageId: postId
                        }).then(() => {
                            toast.success(`Image ${index + 1} updated`);
                            toggleEdit();
                            // router(0);
                        }).catch((err) => {
                            toast.error(err.message)
                        })
                        // const docRef = doc(db, "courses", salonId)
                        // await updateDoc(docRef, {
                        //     imageUrl: url
                        // }).then(() => {
                        //     toast.success("Course updated");
                        //     toggleEdit();
                        //     router.refresh();
                        // }).catch((err) => {
                        //     toast.error(err.message)
                        // })
                    })
                })
            } catch {
                toast.error("Something went wrong");
            }
        })

    }

    return (
        <div className="mt-6 border bg-slate-100 rounded-md p-4">
            {}
            <div className="font-medium flex items-center justify-between">
                Business images
                <Button onClick={toggleEdit} variant="ghost">
                    {isEditing && (
                        <>Cancel</>
                    )}
                    {!isEditing && !initialData.imageUrl && (
                        <>
                            <PlusCircle className="h-4 w-4 mr-2" />
                            Add an image
                        </>
                    )}
                    {!isEditing && initialData.imageUrl && (
                        <>
                            <Pencil className="h-4 w-4 mr-2" />
                            Edit images
                        </>
                    )}
                </Button>
            </div>
            {!isEditing && (
                !initialData.images ? (
                    <div className="flex items-center justify-center h-60 bg-slate-200 rounded-md">
                        <ImageIcon className="h-10 w-10 text-slate-500" />
                    </div>
                ) : (
                    <div className="gap-2 grid grid-cols-3">
                        {Object.values(initialData.images).map((item, index) => (
                            <ImageList index={index} data={item} />
                        ))}
                        {/*<img*/}
                        {/*    alt="Upload"*/}
                        {/*    className="object-cover rounded-md"*/}
                        {/*    src={initialData.imageUrl}*/}
                        {/*/>*/}
                    </div>
                )
            )}
            {isEditing && (
                <div>
                    <Form {...form}>
                        <form action="" onSubmit={form.handleSubmit(onSubmit)}>
                            <FormField
                                control={form.control}
                                name="images"
                                render={({ field }) => (
                                    <FormItem className="w-full">
                                        <FormControl className="h-72">
                                            <FileUpload images={field.value} onFieldChange={field.onChange} setFiles={setFiles}  />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="flex items-center gap-x-2">
                                <Button disabled={!isValid || isSubmitting} type="submit" className="mt-4">
                                    Save
                                </Button>
                            </div>
                        </form>
                    </Form>
                    <div className="text-xs text-muted-foreground mt-4">
                        16:9 aspect ratio recommended
                    </div>
                </div>
            )}
        </div>
    )
}