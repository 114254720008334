export const navLink = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "About Us",
        url: "/about-us"
    },
    {
        title: "Salons",
        url: "/salons"
    },
    {
        title: "Contact Us",
        url: "/contact-us"
    },
]

export const faq = [
    {
        title: "Can I use multiple payment methods for my reservation?",
        description: "Cras iaculis ac amet neque sed consequat mauris. Diam arcu velit lacus venenatis mattis sit lacus. " +
            "Feugiat velit acilisis ut eu id eu nibh tortor viverra volutpat iaculis ut venenatis sit."
    },
    {
        title: "How far in advance should I book my stay with Cruise?",
        description: "Non placerat ut sed sociis eget pharetra vel sit faucibus ullamcorper neque vel rhoncus diam amet " +
            "metus convallis ullamcorper sit proin mauris nam facilisis in."
    },
    {
        title: "What types of accommodations does Cruise offer?",
        description: "Accumsan accumsan id ornare congue nec adipiscing amet. Rhoncus odio eget sed eu habitant justo consectetur " +
            "ullamcorper tincidunt sapien facilisi orci libero."
    },
    {
        title: "Can I request specific amenities or room preferences?",
        description: "Quis habitasse pellentesque sem feugiat scelerisque egestas tincidunt. Tellus nunc nisi velit in ullamcorper. " +
            "iam velit pharetra consectetur ut vel pharetra enim."
    },
    {
        title: "Are there any special offers or discounts available?",
        description: "Nulla orci leo volutpat facilisis morbi. In enim vivamus enim dui hac scelerisque vestibulum Mattis " +
            "sed suscipit nulla arcu wgestas at sed nunc pretium vitae viverra."
    },
    {
        title: "Can I book multiple rooms or accommodations for a group?",
        description: "Porta cras tellus id placerat amet. Neque nulla aliquam sollicitudin augue egestas habitasse eget " +
            "eget vestibulum. Fermentum pretium nulla tortor duis egestas erat vitae faucibus nisl."
    }
]

export const salons = [
    {
        imgUrl: "https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6e20c8d590035eff1b4b_room-main-01.jpg",
        space: "400 sq ft",
        numberOfChairs: "1 Bed",
        numberOfClients: "1-3 People",
        salonName: "Tranquil Oasis Retreat",
        url: "/salon/tranquil-oasis-retreat"
    },
    {
        imgUrl: "https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6e7884663fc4c1909150_room-main-02.jpg",
        space: "600 sq ft",
        numberOfChairs: "2 Beds",
        numberOfClients: "4 People",
        salonName: "Serenity Family Suite",
        url: "/salon/serenity-family-suite"
    },
    {
        imgUrl: "https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6ec5d1781928882d024e_room-main-03.jpg",
        space: "650 sq ft",
        numberOfChairs: "1 Beds",
        numberOfClients: "2 People",
        salonName: "Penthouse Luxury Suite",
        url: "/salon/penthouse-luxury-suite"
    },
    {
        imgUrl: "https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f08a0e22ec5b6c33019_room-main-04.jpg",
        space: "780 sq ft",
        numberOfChairs: "3 Beds",
        numberOfClients: "6 People",
        salonName: "Mountain Escape Lodge",
        url: "/salon/mountain-scape-lodge"
    },
    {
        imgUrl: "https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f494fa4858df4849742_room-main-05.jpg",
        space: "550 sq ft",
        numberOfChairs: "2 Beds",
        numberOfClients: "4 People",
        salonName: "Lakeside Serenity Room",
        url: "/salon/lakeside-serenity-room"
    },
    {
        imgUrl: "https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f801f8b12758a5689ae_room-main-06.jpg",
        space: "300 sq ft",
        numberOfChairs: "1 Beds",
        numberOfClients: "1 People",
        salonName: "Countryside Manor Room",
        url: "/salon/countryside-manor-room"
    },
]

export const provinces = [
    {
        label: "Eastern Cape",
        value: "eastern-cape"
    },
    {
        label: "Free State",
        value: "free-state"
    },
    {
        label: "Gauteng",
        value: "gauteng"
    },
    {
        label: "KwaZulu Natal",
        value: "kwazulu-natal"
    },
    {
        label: "Limpopo",
        value: "limpopo"
    },
    {
        label: "Mpumalanga",
        value: "mpumalanga"
    },
    {
        label: "North West",
        value: "north-west"
    },
    {
        label: "Northern Cape",
        value: "northern-cape"
    },
    {
        label: "Western Cape",
        value: "western-cape"
    }
]

export const categories = [
    { label: "Barber", value: "barber" },
    { label: "Beauty Salon", value: "salon" },
    { label: "Nail Bar", value: "nailBar" },
    { label: "Other", value: "other" },
]

export const paymentOption = [
    { label: "Basic rate (ZAR)", value: "basicRate" },
    { label: "Commission rate (%)", value: "commissionRate" },
    { label: "Commission + Basic rate (ZAR & %)", value: "commissionBasicRate" },
]

export const rentalDuration = [
    { label: "hourly", value: "hourly" },
    { label: "daily", value: "daily" },
    { label: "weekly", value: "weekly" },
    { label: "monthly", value: "monthly" },
]

export const services = [
    {
        id: "free-wifi",
        label: "Free WiFi",
    },
    {
        id: "parking",
        label: "Car Parking",
    },
    {
        id: "proximity",
        label: "Close to town, mall and other",
    }
]