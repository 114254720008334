import React, {useState} from "react";
import {auth, db} from "../firebase";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {Button} from "../components/ui/Button";
import {onValue, ref} from "firebase/database";
import {SEO} from "../seo/SEO";

function LoginPage() {
    const navigate = useNavigate()

    const [data, setData] = useState({
        email: "",
        password: ""
    })

    const handleChange = name => event => {
        const value = event.target.value
        setData({ ...data, [name]: value })
    }

    const login = (e) => {
        e.preventDefault()

        auth.signInWithEmailAndPassword(data.email, data.password).then((authUser) => {
            if (authUser) {
                onValue(ref(db, `mySalons`), snapshot => {
                    if (snapshot.hasChild(authUser.user.uid)) {
                        navigate(`/account/my-salons/${authUser.user.uid}`)
                    } else {
                        navigate("/get-started")
                    }
                })
                toast.success("You are successfully logged in")
            } else {
                toast.error("Something wrong happened.")
            }
        }).catch((err) => {
            toast.error(err.message)
        })
    }

    const redirect = () => {

    }

    return (
        <div>
            <SEO
                title="Login | Let's get started"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/log-in'
            />
            <div className="page-title">
                <div className="w-layout-blockcontainer container w-container">
                    <h1 className="main-title center">Log In</h1>
                </div>
            </div>
            <div className="page-data">
                <div className="w-users-userloginformwrapper login-form">
                    <form data-wf-user-form-type="login" onSubmit={login}>
                        <div className="w-users-userformheader form-field">
                            <div>
                                <label htmlFor="Email" className="field-label">Email</label>
                                <input maxLength="256" placeholder="" name="email" id="wf-log-in-email"
                                       className="input w-input" type="email" autoComplete="email" required="true"
                                       value={data.email} onChange={handleChange("email")} />
                            </div>
                            <div>
                                <label htmlFor="Password" className="field-label">Password</label>
                                <input maxLength="256" placeholder="" name="password" id="wf-log-in-password"
                                       className="input w-input" type="password" required="true"
                                       value={data.password} onChange={handleChange("password")}/>
                            </div>
                        </div>
                        <Button className="bg-[#f64f02] w-full rounded-full my-8">
                            <input type="submit" data-wait="Please wait..."
                                   className="w-users-userformbutton primary-button hover w-button" value="Log In"/>
                        </Button>
                        <div className="w-users-userformfooter">
                            <span>Don &#x27;t have an account?</span>
                            <a href="/sign-up" className="w-inline-block">Sign Up</a>
                        </div>
                    </form>
                    <div style={{display: "none"}} data-wf-user-form-error="true"
                         className="w-users-userformerrorstate error-message w-form-fail">
                        <div className="user-form-error-msg"
                             wf-login-form-general-error-error="We&#x27;re having trouble logging you in. Please try again, or contact us if you continue to have problems."
                             wf-login-form-invalid-email_or_password-error="Invalid email or password. Please try again.">We &#x27;re
                            having trouble logging you in. Please try again, or contact us if you continue to have
                            problems.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage