import './App.css';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import AboutUsPage from "./pages/AboutUsPage";
import SalonPage from "./pages/SalonPage";
import ContactUsPage from "./pages/ContactUsPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import SalonDetailPage from "./pages/SalonDetailPage";
import RegisterSalonPage from "./pages/RegisterSalonPage";
import GetStartedPage from "./pages/GetStartedPage";
import TestPage from "./pages/TestPage";
import TestOnePage from "./pages/TestOnePage";
import MySalonPage from "./pages/MySalonPage";

function App() {
  return (
      <BrowserRouter>
          <LandingPage />
          <RegisterLayout />
      </BrowserRouter>
  );
}

const LandingPage = () => {
    const location = useLocation()

    const isRegister = location.pathname.includes("/register")
    const isAccount = location.pathname.includes("/account")
    const hideNav = location.pathname === "/get-started" || isRegister || isAccount

    return (
        <>
            {!hideNav &&
                <div>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/about-us" element={<AboutUsPage/>}/>
                        <Route path="/salons" element={<SalonPage/>}/>
                        <Route path="/contact-us" element={<ContactUsPage/>}/>
                        <Route path="/log-in" element={<LoginPage/>}/>
                        <Route path="/sign-up" element={<SignUpPage/>}/>
                        <Route path="/salon/:salonName" element={<SalonDetailPage/>}/>
                    </Routes>
                    <Footer/>
                </div>
            }
        </>
    )

}

const RegisterLayout = () => {
    const location = useLocation()

    const isRegister = location.pathname.includes("/register")
    const isAccount = location.pathname.includes("/account")
    const hideNav = location.pathname === "/get-started" || isRegister || isAccount

    return (
        <div>
            {hideNav &&
                <Routes>
                    <Route path="/get-started" element={<GetStartedPage/>}/>
                    <Route path="/register/:salonId" element={<RegisterSalonPage/>}/>
                    <Route path="/account/my-salons/:userId" element={<MySalonPage />}/>
                </Routes>
            }
        </div>
    )

}

const SalonAdminLayout = () => {
    const location = useLocation()

    const isRegister = location.pathname.includes("/account/my-salons")
    const hideNav = location.pathname === "/get-started" || isRegister

    return (
        <div>
            {!hideNav &&
                <Routes>
                    <Route path="/account/my-salons/:userId" element={<MySalonPage />}/>
                </Routes>
            }
        </div>
    )

}

export default App;
