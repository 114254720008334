import React from "react";
import {salons} from "../lib/constant";
import Salon from "../components/Salon";
import {Skeleton} from "../components/ui/Skeleton";
import {SEO} from "../seo/SEO";

function SalonPage() {
    return (
        <div>
            <SEO
                title="Salons | Explore a wide range of salons renting a chair"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/salons'
            />
            <div className="page-title">
                <div className="w-layout-blockcontainer container w-container">
                    <div className="sub-title">
                        <img
                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                            loading="lazy" alt="Title Icon"/>
                        <div>RENT A CHAIR</div>
                    </div>
                    <h1 className="main-title">Explore Salons</h1>
                </div>
            </div>
            <div className="page-data">
                <section className="rooms">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="w-dyn-list">
                            <div role="list" className="room-list w-dyn-items">
                                {salons.map((item, key) => (
                                    <div className="flex flex-col space-y-3">
                                        <Skeleton className="h-[280px] w-full rounded-xl"/>
                                        <div className="space-y-2">
                                            <Skeleton className="h-4 w-[250px]"/>
                                            <Skeleton className="h-4 w-[200px]"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div role="navigation" aria-label="List" className="w-pagination-wrapper pagination">
                                <a data-w-id="97e58f71-160a-0910-02a4-30ebe533a99e" href="?86691e23_page=2"
                                   aria-label="Next Page" className="w-pagination-next button">
                                    <div className="primary-button gap-x-1">
                                    <div>Next</div>
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                            loading="lazy"
                                            style={{transform: `translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                            alt="Arrow" className="arrow"/>
                                    </div>
                                </a>
                                <link rel="prerender" href="?86691e23_page=2"/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default SalonPage