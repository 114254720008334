import { ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "ZAR"
    }).format(price)
}

export const isTeacher = (userId) => {
    return userId === "Y9BJ9uctEwQ36QWUaWibOtJsdBk2";
}

export const convertFileToUrl = (file) => URL.createObjectURL(file)