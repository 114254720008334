import React from "react";
import imgThree from "../media/assets/img-three.png"

function HowWeWork() {
    return (
        <section className="service">
            <div className="w-layout-blockcontainer container w-container">
                <div className="service-wrap">
                    <div data-w-id="44f4c277-9fb1-b45d-de83-235790a60e72" className="service-img">
                        <img src={imgThree} loading="lazy" alt="Service Image" className="service-image"
                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 48vw"
                            srcSet={`${imgThree} 500w, ${imgThree} 800w, ${imgThree} 1080w, ${imgThree} 1296w`}
                        />
                    </div>
                    <div className="service-data">
                        <h2 className="service-title">HOW WE WORK</h2>
                        <div>
                            <div data-w-id="44f4c277-9fb1-b45d-de83-235790a60e78" className="service-block">
                                <div className="service-number">01</div>
                                <div>
                                    <div className="service-info">
                                        <h4 className="service-heading">Create an Account</h4>
                                    </div>
                                    <p className="service-text">
                                        Let's get to know you by creating your account using your personal details.
                                    </p>
                                </div>
                            </div>
                            <div data-w-id="44f4c277-9fb1-b45d-de83-235790a60e84" className="service-block">
                                <div className="service-number">02</div>
                                <div>
                                    <div className="service-info">
                                        <h4 className="service-heading">List your Salon</h4>
                                    </div>
                                    <p className="service-text">
                                        Register your business or salon details and send for verification.
                                    </p>
                                </div>
                            </div>
                            <div data-w-id="44f4c277-9fb1-b45d-de83-235790a60e90" className="service-block bottom">
                                <div className="service-number">03</div>
                                <div>
                                    <div className="service-info">
                                        <h4 className="service-heading">Accept Rent-A-Chair Requests</h4>
                                    </div>
                                    <p className="service-text">
                                        Set your rates and start receiving rental request from clients.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowWeWork