import React, {useState} from "react";
import {Button} from "./ui/Button";
import {auth, db} from "../firebase";
import {useNavigate} from "react-router-dom";
import {onValue, ref} from "firebase/database";

const ListButton = () => {
    const navigate = useNavigate()

    const handleClick = () => {
        if (auth?.currentUser) {
            onValue(ref(db, `/mySalons/${auth?.currentUser?.uid}`), snapshot => {
                if (snapshot.exists()) {
                    navigate(`/account/my-salons/${auth?.currentUser?.uid}`)
                } else {
                    navigate(`/get-started`)
                }
            })
        } else {
            navigate(`/log-in`)

        }
    }
    return (
        <Button className="bg-[#f64f02] gap-x-2 rounded-full" onClick={handleClick}>
            <div>List your Salon Now!</div>
            <div className="client-image-wrap">
                <div className="client-img">
                    <img
                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665acea8423760dc2ad4471a_cta-btn-img-1.jpg"
                        loading="lazy" alt="CTA Button Image" className="client-image"/>
                </div>
                <div className="client-img">
                    <img
                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665acea811d090998d41a257_cta-btn-img-2.jpg"
                        loading="lazy" alt="CTA Button Image" className="client-image"/>
                </div>
                <div className="client-img">
                    <img
                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665acea8aa5d8b71d0db4ec9_cta-btn-img-3.jpg"
                        loading="lazy" alt="CTA Button Image" className="client-image"/>
                </div>
            </div>
        </Button>
    )
}

export default ListButton