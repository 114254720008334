import React, {useEffect, useState} from "react";
import {z} from "zod"
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {Button} from "../ui/Button";
import {Pencil} from "lucide-react";
import {cn, formatPrice} from "../../lib/utils";
import {Form, FormControl, FormDescription, FormField, FormItem, FormMessage} from "../ui/Form";
import {Input} from "../ui/Input";
import {db} from "../../firebase";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/Select";
import {categories, paymentOption, rentalDuration} from "../../lib/constant";
import {Checkbox} from "../ui/Checkbox";

const formSchema = z.object({
    price: z.coerce.number().min(1, {
        message: "Price is required"
    }),
    rentalDuration: z.string().min(1, {
        message: "Rental duration is required"
    })
});

export const PriceForm = ({initialData, salonId}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [priceOption, setPriceOption] = useState("");

    const toggleEdit = () => setIsEditing((current) => !current);

    const router = useNavigate();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            price: initialData?.price || undefined,
            rentalDuration: initialData.rentalDuration || ""
        },
    });

    const { isSubmitting, isValid } = form.formState;

    const onSubmit = async (values) => {
        try {
            db.ref('salons').child(`/${salonId}`).update({
                price: values.price,
                rentalDuration: values.rentalDuration
            }).then(() => {
                toast.success("Rental rates updated");
                toggleEdit();
                // router(0);
            }).catch((err) => {
                toast.error(err.message)
            })
        } catch {
            toast.error("Something went wrong");
        }
    }

    return (
        <div className="mt-6 border bg-slate-100 rounded-md p-4">
            <div className="font-medium flex items-center justify-between">
                Rental price
                <Button onClick={toggleEdit} variant="ghost">
                    {isEditing ? (
                        <>Cancel</>
                    ) : (
                        <>
                            <Pencil className="h-4 w-4 mr-2" />
                            Edit price
                        </>
                    )}
                </Button>
            </div>
            {!isEditing && (
                <p className={cn(
                    "text-sm mt-2",
                    !initialData.price && "text-slate-500 italic"
                )}>
                    {initialData.price ? `${formatPrice(initialData.price)} / ${initialData.rentalDuration}` : "No price"}
                </p>
            )}
            {isEditing && (
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                        <div className="grid grid-cols-2 gap-x-8 w-full">
                            <FormField
                                control={form.control}
                                name="price"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                className={`${initialData.paymentOption === "basicRate" ? "w-full" : "hidden"}`}
                                                type="number"
                                                step="1"
                                                disabled={isSubmitting}
                                                placeholder="Set a basic rate"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="price"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                className={`${initialData.paymentOption === "commissionRate" ? "w-full" :  "hidden"}`}
                                                type="number"
                                                step="1"
                                                disabled={isSubmitting}
                                                placeholder="Set a commission rate"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <FormField control={form.control} name="rentalDuration"
                                   render={({ field }) => (
                                       <FormItem className="w-full">
                                           <Select onValueChange={field.onChange} defaultValue={field.value}>
                                               <FormControl>
                                                   <SelectTrigger>
                                                       <SelectValue placeholder="Select rental duration" {...field} />
                                                   </SelectTrigger>
                                               </FormControl>
                                               <SelectContent>
                                                   {rentalDuration.map((duration) => (
                                                       <SelectItem value={duration.value} className="cursor-pointer">
                                                           {duration.label}
                                                       </SelectItem>
                                                   ))}
                                               </SelectContent>
                                           </Select>
                                           <FormMessage />
                                       </FormItem>
                                   )}
                        />
                        <div className="flex items-center gap-x-2">
                            <Button
                                disabled={!isValid || isSubmitting}
                                type="submit"
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            )}
        </div>
    )
}