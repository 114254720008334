import React from "react";
import ReactImageUploading from "react-images-uploading";
import {Button} from "./ui/Button";
import {CloudUpload, File, ImageUp, Loader2, Pencil, Trash, Trash2, X} from "lucide-react";
import {Card} from "./ui/Card";

function FileUpload({ images, onFieldChange, setFiles, type  }) {
    // const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        // setImages(imageList);
        setFiles(imageList)
    };

    return (
        <div>
            <ReactImageUploading
                multiple
                value={images}
                onChange={onFieldChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["jpg", "png"]}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        <div className="flex justify-between mb-4">
                            <div></div>
                            <div className="flex gap-x-4">
                                <Button variant="outline" size="icon" style={isDragging ? {color: "red"} : null}
                                        onClick={onImageUpload}{...dragProps}>
                                    <CloudUpload className="h-4 w-4" />
                                </Button>
                                <Button onClick={onImageRemoveAll} size="icon" variant="destructive">
                                    <Trash2 className="h-4 w-4" />
                                </Button>
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 w-full">
                            {imageList.map((image, index) => (
                                <div key={index}
                                     className="flex items-center p-3 w-full bg-sky-100 border-sky-200 border text-sky-700 rounded-md">
                                    <img src={image.data_url} alt=""
                                         className="h-16 w-16 object-cover mr-2 flex-shrink-0"/>
                                    {/*<p className="text-xs line-clamp-1">*/}
                                    {/*    {image.file.name}*/}
                                    {/*</p>*/}
                                    <div className="ml-auto flex flex-col">
                                        <Button onClick={() => onImageUpdate(index)} size="sm" variant="ghost"
                                                className="ml-auto hover:opacity-75 transition">
                                            <Pencil className="h-4 w-4"/>
                                        </Button>
                                        <Button onClick={() => onImageRemove(index)} size="sm" variant="ghost"
                                                className="ml-auto hover:opacity-75 transition">
                                            <X className="h-4 w-4"/>
                                        </Button>
                                    </div>
                                </div>
                                // <Card key={index} className="w-full">
                                //     <Card>
                                //         <img src={image.data_url} alt="" className="w-full h-24 rounded object-cover"/>
                                //     </Card>
                                //     <div className="flex justify-between pt-4 pb-2 px-2">
                                //     <Button onClick={() => onImageUpdate(index)} size="sm" variant="ghost">
                                //             Update
                                //         </Button>
                                //         <Button onClick={() => onImageRemove(index)} size="sm" variant="ghost">
                                //             <Trash className="h-4 w-4 text-red-700"/>
                                //         </Button>
                                //     </div>
                                // </Card>
                            ))}
                        </div>
                    </div>
                )}
            </ReactImageUploading>
        </div>
    )
}

export default FileUpload