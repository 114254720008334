import React, {useEffect, useState} from "react";
import DataTable from "../components/DataTable";
import {columns} from "../components/Columns";
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "../components/ui/Card";
import {Link, useParams} from "react-router-dom";
import {Button} from "../components/ui/Button";
import {LogOut, UserCircle} from "lucide-react";
import {auth, db} from "../firebase";
import {onValue, ref} from "firebase/database";
import logo from "../media/assets/logo1.jfif"
import {SEO} from "../seo/SEO";

function MySalonPage() {
    const {userId} = useParams()

    const [username, setUsername] = useState("")
    const [salons, setSalons] = useState([])

    useEffect(() => {
        onValue(ref(db, `/account/${userId}`), snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.child("firstName").val()
                setUsername(data)
            }
        })
        onValue(ref(db, `/mySalons/${userId}`), snapshot => {
            if (snapshot.exists()) {
                setSalons([])
                const data = snapshot.val()
                if (data !== null) {
                    Object.values(data).map((item) => {
                        setSalons((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [userId]);

    return (
        <div>
            <SEO
                title="My Salon | Add or edit your salon profile"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/account/my-salons'
            />
            <div className="flex items-center justify-between px-4 shadow">
                <div>
                    <CardHeader>
                        <div className="flex gap-x-2">
                            <img src={logo} alt="" className="h-10 w-10"/>
                            <div className="hidden md:block">
                                <CardTitle>
                                    Rent-A-Chair
                                </CardTitle>
                                <CardDescription className="-mt-2.5">
                                    Short-term salon rental
                                </CardDescription>
                            </div>
                        </div>
                    </CardHeader>
                </div>
                <div className="flex items-center gap-x-4">
                    <Link to="/">
                        <Button variant="ghost" className="gap-x-2">
                            <LogOut className="h-4 w-4"/>
                            <span className="text-lg font-semibold hidden md:block">Exit</span>
                        </Button>
                    </Link>
                    <p className="mb-0 hidden text-lg md:block">
                        Welcome {username}
                    </p>
                    <UserCircle className="md:hidden" />
                </div>
            </div>
            <div className="p-6">
                <DataTable columns={columns} data={salons}/>
            </div>
        </div>
    )
}

export default MySalonPage