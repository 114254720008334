import React from "react";

function Salon({ item, key }) {
    return (
        <div role="listitem" className="room-item w-dyn-item" key={key}>
            <a data-w-id="a72fb718-089b-2900-c018-37bf2e6c422f" href={item.url} className="room-block w-inline-block">
                <div className="room-img">
                    <img loading="lazy" className="room-image"
                         style={{transform: `translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                         data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                         alt="Room Image" src={item.imgUrl} sizes="(max-width: 479px) 100vw, (max-width: 767px) 46vw, 47vw"
                    />
                </div>
                <div>
                    <div className="room-info">
                        <div className="room-info-block">
                            <img
                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362f53f09871d3569a0_ic-sq.svg"
                                loading="lazy" alt="Room Icon"/>
                            <div>{item.space}</div>
                        </div>
                        <div className="room-info-block">
                            <img
                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362814c672af61e24d8_ic-bed.svg"
                                loading="lazy" alt="Room Icon"/>
                            <div>{item.numberOfChairs}</div>
                        </div>
                        <div className="room-info-block">
                            <img
                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d83620edef77fe22b05bb_ic-person.svg"
                                loading="lazy" alt="Room Icon"/>
                            <div>{item.numberOfClients}</div>
                        </div>
                    </div>
                    <h4 className="room-title">{item.salonName}</h4>
                </div>
            </a>
        </div>
    )
}

export default Salon