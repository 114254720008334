import React from "react";

function SalonDetailPage() {
    return (
        <div>
            <div className="page-title">
                <div className="w-layout-blockcontainer container w-container">
                    <div className="sub-title">
                        <img
                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                            loading="lazy" alt="Title Icon"/>
                        <div>Explore Room</div>
                    </div>
                    <h1 className="room-main-title">Tranquil Oasis Retreat</h1>
                </div>
            </div>
            <div className="page-data">
                <section className="room-main">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="room-wrap">
                            <div className="room-left">
                                <div className="room-main-img">
                                    <img loading="eager"
                                         data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                                         src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6e20c8d590035eff1b4b_room-main-01.jpg"
                                         alt="Room Main Image"
                                         sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 70vw"
                                         srcSet="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6e20c8d590035eff1b4b_room-main-01-p-500.jpg 500w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6e20c8d590035eff1b4b_room-main-01-p-800.jpg 800w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6e20c8d590035eff1b4b_room-main-01-p-1080.jpg 1080w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6e20c8d590035eff1b4b_room-main-01.jpg 1800w"
                                         className="room-main-image"/>
                                    <div className="more-images">
                                        <a href="#"
                                           data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22media%22%7D%5D"
                                           className="view-btn w-inline-block w-lightbox">
                                            <div>More Images</div>
                                            <script type="application/json" className="w-json">
                                            </script>
                                        </a>
                                    </div>
                                    <a href="https://webflow.com/templates/html/cruise-hotel-website-template"
                                       target="_blank" className="purchase-badge w-inline-block">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665995c4d1e1c5bb1639daff_ic-webflow.svg"
                                            loading="lazy" width="Auto" alt="Webflow Icon" className="webflow-icon"/>
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665995c4d1e1c5bb1639dafb_badge-text.svg"
                                            loading="lazy" alt=""/>
                                    </a>
                                    <div className="d-none w-dyn-list w-dyn-items-repeater-ref">
                                        <script type="text/x-wf-template"
                                                id="wf-template-634d9ea4-7676-14d1-8e26-db1bda5d4772">
                                            %3Cdiv%20role%3D%22listitem%22%20class%3D%22w-dyn-item%20w-dyn-repeater-item%22%3E%3Ca%20href%3D%22%23%22%20data-wf-sku-bindings%3D%22%255B%257B%2522from%2522%253A%2522f_more_images_4dr%255B%255D%2522%252C%2522to%2522%253A%2522media%2522%257D%255D%22%20class%3D%22w-inline-block%20w-lightbox%22%3E%3Cimg%20loading%3D%22lazy%22%20data-wf-sku-bindings%3D%22%255B%257B%2522from%2522%253A%2522f_more_images_4dr%255B%255D%2522%252C%2522to%2522%253A%2522src%2522%257D%255D%22%20src%3D%22https%3A%2F%2Fcdn.prod.website-files.com%2F665ad48cef8cc551376f7777%2F6662c6f3dea3ba56407c0889_room-thumb-03.jpg%22%20alt%3D%22%22%20sizes%3D%22100vw%22%20srcset%3D%22https%3A%2F%2Fcdn.prod.website-files.com%2F665ad48cef8cc551376f7777%2F6662c6f3dea3ba56407c0889_room-thumb-03-p-500.jpg%20500w%2C%20https%3A%2F%2Fcdn.prod.website-files.com%2F665ad48cef8cc551376f7777%2F6662c6f3dea3ba56407c0889_room-thumb-03-p-800.jpg%20800w%2C%20https%3A%2F%2Fcdn.prod.website-files.com%2F665ad48cef8cc551376f7777%2F6662c6f3dea3ba56407c0889_room-thumb-03-p-1080.jpg%201080w%2C%20https%3A%2F%2Fcdn.prod.website-files.com%2F665ad48cef8cc551376f7777%2F6662c6f3dea3ba56407c0889_room-thumb-03.jpg%201266w%22%2F%3E%3Cscript%20type%3D%22application%2Fjson%22%20class%3D%22w-json%22%3E%7B%0A%20%20%22items%22%3A%20%5B%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22url%22%3A%20%22https%3A%2F%2Fcdn.prod.website-files.com%2F665ad48cef8cc551376f7777%2F6662c6f3dea3ba56407c0889_room-thumb-03.jpg%22%2C%0A%20%20%20%20%20%20%22type%22%3A%20%22image%22%0A%20%20%20%20%7D%0A%20%20%5D%2C%0A%20%20%22group%22%3A%20%22Group%22%0A%7D%3C%2Fscript%3E%3C%2Fa%3E%3C%2Fdiv%3E
                                        </script>
                                        <div role="list" className="w-dyn-items" data-wf-collection="f_more_images_4dr"
                                             data-wf-template-id="wf-template-634d9ea4-7676-14d1-8e26-db1bda5d4772">
                                            <div role="listitem" className="w-dyn-item w-dyn-repeater-item">
                                                <a href="#"
                                                   data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_more_images_4dr%5B%5D%22%2C%22to%22%3A%22media%22%7D%5D"
                                                   className="w-inline-block w-lightbox">
                                                    <img loading="lazy"
                                                         data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_more_images_4dr%5B%5D%22%2C%22to%22%3A%22src%22%7D%5D"
                                                         src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f3dea3ba56407c0889_room-thumb-03.jpg"
                                                         alt="" sizes="100vw"
                                                         srcSet="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f3dea3ba56407c0889_room-thumb-03-p-500.jpg 500w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f3dea3ba56407c0889_room-thumb-03-p-800.jpg 800w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f3dea3ba56407c0889_room-thumb-03-p-1080.jpg 1080w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f3dea3ba56407c0889_room-thumb-03.jpg 1266w"/>
                                                    <script type="application/json" className="w-json">
                                                    </script>
                                                </a>
                                            </div>
                                            <div role="listitem" className="w-dyn-item w-dyn-repeater-item">
                                                <a href="#"
                                                   data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_more_images_4dr%5B%5D%22%2C%22to%22%3A%22media%22%7D%5D"
                                                   className="w-inline-block w-lightbox">
                                                    <img loading="lazy"
                                                         data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_more_images_4dr%5B%5D%22%2C%22to%22%3A%22src%22%7D%5D"
                                                         src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f33dc92c075e9aa709_room-thumb-04.jpg"
                                                         alt="" sizes="100vw"
                                                         srcSet="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f33dc92c075e9aa709_room-thumb-04-p-500.jpg 500w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f33dc92c075e9aa709_room-thumb-04-p-800.jpg 800w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f33dc92c075e9aa709_room-thumb-04-p-1080.jpg 1080w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f33dc92c075e9aa709_room-thumb-04.jpg 1266w"/>
                                                    <script type="application/json" className="w-json">

                                                    </script>
                                                </a>
                                            </div>
                                            <div role="listitem" className="w-dyn-item w-dyn-repeater-item">
                                                <a href="#"
                                                   data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_more_images_4dr%5B%5D%22%2C%22to%22%3A%22media%22%7D%5D"
                                                   className="w-inline-block w-lightbox">
                                                    <img loading="lazy"
                                                         data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_more_images_4dr%5B%5D%22%2C%22to%22%3A%22src%22%7D%5D"
                                                         src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f38aca5e65dd0c474e_room-thumb-07.jpg"
                                                         alt="" sizes="100vw"
                                                         srcSet="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f38aca5e65dd0c474e_room-thumb-07-p-500.jpg 500w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f38aca5e65dd0c474e_room-thumb-07-p-800.jpg 800w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f38aca5e65dd0c474e_room-thumb-07-p-1080.jpg 1080w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/6662c6f38aca5e65dd0c474e_room-thumb-07.jpg 1266w"/>
                                                    <script type="application/json" className="w-json">

                                                    </script>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="w-dyn-hide w-dyn-empty">
                                            <div>No items found.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="room-details w-richtext">
                                    <p>In morbi urna diam non. Nam egestas id odio arcu bibendum ipsum eleifend. Tellus
                                        posuere mattis gravida sed. Lacus neque morbi ornare faucibus quam tincidunt
                                        velit urna adipiscing. Commodo sit ut quis sed vitae. Mauris tristique placerat
                                        pharetra imperdiet fringilla magna mollis tristique volutpat. In cras egestas
                                        sagittis amet eleifend nullam nisi tempor posuere. Nec pulvinar vitae fusce
                                        vulputate fusce metus senectus dui mi. Potenti ut dolor amet blandit nulla.</p>
                                    <p>Diam faucibus nisi arcu non in. Dui diam ut risus justo semper. Adipiscing orci
                                        nec nunc faucibus lacus. Nec iaculis eu metus dapibus. Risus consequat purus
                                        cursus quis sem. Commodo justo vulputate ipsum pretium metus dolor pellentesque.
                                        In orci velit odio sed massa est et etiam.</p>
                                </div>
                                <div>
                                    <h3 className="room-heading">Amenities</h3>
                                    <div className="w-dyn-list">
                                        <div role="list" className="amenity-list w-dyn-items">
                                            <div role="listitem" className="amenity-item w-dyn-item">
                                                <div className="amenity-block">
                                                    <div className="amenity-info">
                                                        <div className="amenity-title">Airport Pickup</div>
                                                        <img loading="lazy"
                                                             src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d63c894951391d2497a8a_ic-airplane.svg"
                                                             alt="Amenity Icon" className="amenity-icon"/>
                                                    </div>
                                                    <div>Vestibulum pretium tortor purus ut nulla pulvinar neque.</div>
                                                </div>
                                            </div>
                                            <div role="listitem" className="amenity-item w-dyn-item">
                                                <div className="amenity-block">
                                                    <div className="amenity-info">
                                                        <div className="amenity-title">Fiber Internet</div>
                                                        <img loading="lazy"
                                                             src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d63ecbc07a265e7fe79c5_ic-fiber.svg"
                                                             alt="Amenity Icon" className="amenity-icon"/>
                                                    </div>
                                                    <div>Diam euismod purus sagittis magna donec pellentesque arcu.
                                                    </div>
                                                </div>
                                            </div>
                                            <div role="listitem" className="amenity-item w-dyn-item">
                                                <div className="amenity-block">
                                                    <div className="amenity-info">
                                                        <div className="amenity-title">Fitness Center</div>
                                                        <img loading="lazy"
                                                             src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6419431bcc1d02533e6a_ic-fitness.svg"
                                                             alt="Amenity Icon" className="amenity-icon"/>
                                                    </div>
                                                    <div>Gravida morbi dictum hendrerit id etiam duis dignissim vitae
                                                        arcu.
                                                    </div>
                                                </div>
                                            </div>
                                            <div role="listitem" className="amenity-item w-dyn-item">
                                                <div className="amenity-block">
                                                    <div className="amenity-info">
                                                        <div className="amenity-title">Laundry Services</div>
                                                        <img loading="lazy"
                                                             src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6427d7ee4269a7511f8e_ic-laundry.svg"
                                                             alt="Amenity Icon" className="amenity-icon"/>
                                                    </div>
                                                    <div>Id cras sit molestie eget a que tempus blandit sed ultricies
                                                        sed.
                                                    </div>
                                                </div>
                                            </div>
                                            <div role="listitem" className="amenity-item w-dyn-item">
                                                <div className="amenity-block">
                                                    <div className="amenity-info">
                                                        <div className="amenity-title">Lunch &amp;Breakfast</div>
                                                        <img loading="lazy"
                                                             src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6449bc07a265e7feced8_ic-lunch.svg"
                                                             alt="Amenity Icon" className="amenity-icon"/>
                                                    </div>
                                                    <div>Odio felis vitae nullam ipsum oncus eu vestibulum.</div>
                                                </div>
                                            </div>
                                            <div role="listitem" className="amenity-item w-dyn-item">
                                                <div className="amenity-block">
                                                    <div className="amenity-info">
                                                        <div className="amenity-title">Swimming Pool</div>
                                                        <img loading="lazy"
                                                             src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6439f6fe408cd0697b72_ic-swimming.svg"
                                                             alt="Amenity Icon" className="amenity-icon"/>
                                                    </div>
                                                    <div>Sit feugiat ac odio fermentum eleifend viverra enim velit
                                                        risus.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="room-heading">Room Rules</h3>
                                    <div className="room-rules w-richtext">
                                        <ul role="list">
                                            <li>Check-in time is 3:00 PM; check-out time is 11:00 AM.</li>
                                            <li>No smoking allowed inside the room. Designated smoking areas are
                                                available outside.
                                            </li>
                                            <li>Please conserve water and electricity by turning off lights and
                                                appliances when not in use.
                                            </li>
                                            <li>Guests are responsible for locking the doors and securing their
                                                belongings.
                                            </li>
                                            <li>Room keys must be returned at check-out. Lost keys incur a replacement
                                                fee.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="room-right">
                                <div>
                                    <div>
                                        <h5 className="room-sub-title">Information</h5>
                                        <div className="room-information">
                                            <div className="room-info-block">
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362f53f09871d3569a0_ic-sq.svg"
                                                    loading="lazy" alt="Room Icon"/>
                                                <div>400 sq ft</div>
                                            </div>
                                            <div className="room-info-block">
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d83620edef77fe22b05bb_ic-person.svg"
                                                    loading="lazy" alt="Room Icon"/>
                                                <div>1-3 People</div>
                                            </div>
                                            <div className="room-info-block">
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362814c672af61e24d8_ic-bed.svg"
                                                    loading="lazy" alt="Room Icon"/>
                                                <div>1 Bed</div>
                                            </div>
                                            <div className="room-info-block">
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d9144269de6b13340eecb_bathroom.svg"
                                                    loading="lazy" alt="Room Icon"/>
                                                <div>2 Bathrooms</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room-price">
                                        <div>Per Night:</div>
                                        <div
                                            data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D"
                                            className="price">$ 170.00 USD
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="room-sub-title">Extra Services</h5>
                                        <div className="es-wrap">
                                            <div className="extra-service">
                                                <div>Free Private Parking</div>
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d938c26b835330a45ccc4_ic-parking.svg"
                                                    loading="lazy" alt="Extra Service Icon"/>
                                            </div>
                                            <div className="extra-service">
                                                <div>Kids Play Area</div>
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d938cd7a7c76f6bb4324f_ic-child.svg"
                                                    loading="lazy" alt="Extra Service Icon"/>
                                            </div>
                                            <div className="extra-service">
                                                <div>Free Early Check in</div>
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d938cd81f75be3ca17c71_ic-cehck-in.svg"
                                                    loading="lazy" alt="Extra Service Icon"/>
                                            </div>
                                            <div className="extra-service">
                                                <div>Delicious Meals &amp;Snacks</div>
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d938cba91c9808c3b2760_ic.svg"
                                                    loading="lazy" alt="Extra Service Icon"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <form data-node-type="commerce-add-to-cart-form"
                                          data-commerce-sku-id="665d6d6d8ad6441a1ddfc7bd"
                                          data-loading-text="Adding to cart..."
                                          data-commerce-product-id="665d6d6c32165a0692966924"
                                          className="w-commerce-commerceaddtocartform default-state">
                                        <div className="d-none">
                                            <label htmlFor="quantity-1deb75df986fc7d20f153b246631472d">Quantity</label>
                                            <input type="number" pattern="^[0-9]+$" inputMode="numeric"
                                                   id="quantity-1deb75df986fc7d20f153b246631472d"
                                                   name="commerce-add-to-cart-quantity-input" min="1"
                                                   className="w-commerce-commerceaddtocartquantityinput" value="1"/>
                                        </div>
                                        <input type="submit" data-node-type="commerce-add-to-cart-button"
                                               data-loading-text="Book Room" aria-busy="false" aria-haspopup="dialog"
                                               className="w-commerce-commerceaddtocartbutton primary-button hover"
                                               value="Book Room"/>
                                    </form>
                                    <div style={{display: "none"}}
                                         className="w-commerce-commerceaddtocartoutofstock empty-state" tabIndex="0">
                                        <div>This product is out of stock.</div>
                                    </div>
                                    <div aria-live="assertive" data-node-type="commerce-add-to-cart-error"
                                         style={{display: "none"}}
                                         className="w-commerce-commerceaddtocarterror error-message">
                                        <div data-node-type="commerce-add-to-cart-error"
                                             data-w-add-to-cart-quantity-error="Product is not available in this quantity."
                                             data-w-add-to-cart-general-error="Something went wrong when adding this item to the cart."
                                             data-w-add-to-cart-mixed-cart-error="You can’t purchase another product with a subscription."
                                             data-w-add-to-cart-buy-now-error="Something went wrong when trying to purchase this item."
                                             data-w-add-to-cart-checkout-disabled-error="Checkout is disabled on this site."
                                             data-w-add-to-cart-select-all-options-error="Please select an option in each set.">Product
                                            is not available in this quantity.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="rooms">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="section-title">
                            <div className="sub-title space">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                                    loading="lazy" alt="Title Icon"/>
                                <div>Luxury Comfort</div>
                            </div>
                            <h2 className="section-heading">Similar Rooms</h2>
                        </div>
                        <div className="w-dyn-list">
                            <div role="list" className="room-list w-dyn-items">
                                <div role="listitem" className="room-item w-dyn-item">
                                    <a data-w-id="b118da25-7383-5b70-40e2-bcce90f0853b"
                                       href="/product/lakeside-serenity-room" className="room-block w-inline-block">
                                        <div className="room-img">
                                            <img loading="lazy"
                                                 style={{transform: `translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                                 data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                                                 alt="Room Image"
                                                 src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f494fa4858df4849742_room-main-05.jpg"
                                                 sizes="(max-width: 479px) 92vw, (max-width: 767px) 46vw, (max-width: 991px) 47vw, 46vw"
                                                 srcSet="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f494fa4858df4849742_room-main-05-p-500.jpg 500w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f494fa4858df4849742_room-main-05-p-800.jpg 800w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f494fa4858df4849742_room-main-05-p-1080.jpg 1080w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f494fa4858df4849742_room-main-05-p-1600.jpg 1600w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f494fa4858df4849742_room-main-05.jpg 1800w"
                                                 className="room-image"/>
                                        </div>
                                        <div>
                                            <div className="room-info">
                                                <div className="room-info-block">
                                                    <img
                                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362f53f09871d3569a0_ic-sq.svg"
                                                        loading="lazy" alt="Room Icon"/>
                                                    <div>550 sq ft</div>
                                                </div>
                                                <div className="room-info-block">
                                                    <img
                                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362814c672af61e24d8_ic-bed.svg"
                                                        loading="lazy" alt="Room Icon"/>
                                                    <div>2 Beds</div>
                                                </div>
                                                <div className="room-info-block">
                                                    <img
                                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d83620edef77fe22b05bb_ic-person.svg"
                                                        loading="lazy" alt="Room Icon"/>
                                                    <div>4 People</div>
                                                </div>
                                            </div>
                                            <h4 className="room-title">Lakeside Serenity Room</h4>
                                        </div>
                                    </a>
                                </div>
                                <div role="listitem" className="room-item w-dyn-item">
                                    <a data-w-id="b118da25-7383-5b70-40e2-bcce90f0853b"
                                       href="/product/countryside-manor-room" className="room-block w-inline-block">
                                        <div className="room-img">
                                            <img loading="lazy"
                                                 style={{transform: `translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                                 data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                                                 alt="Room Image"
                                                 src="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f801f8b12758a5689ae_room-main-06.jpg"
                                                 sizes="(max-width: 479px) 92vw, (max-width: 767px) 46vw, (max-width: 991px) 47vw, 46vw"
                                                 srcSet="https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f801f8b12758a5689ae_room-main-06-p-500.jpg 500w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f801f8b12758a5689ae_room-main-06-p-800.jpg 800w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f801f8b12758a5689ae_room-main-06-p-1080.jpg 1080w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f801f8b12758a5689ae_room-main-06-p-1600.jpg 1600w, https://cdn.prod.website-files.com/665ad48cef8cc551376f7777/665d6f801f8b12758a5689ae_room-main-06.jpg 1800w"
                                                 className="room-image"/>
                                        </div>
                                        <div>
                                            <div className="room-info">
                                                <div className="room-info-block">
                                                    <img
                                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362f53f09871d3569a0_ic-sq.svg"
                                                        loading="lazy" alt="Room Icon"/>
                                                    <div>300 sq ft</div>
                                                </div>
                                                <div className="room-info-block">
                                                    <img
                                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d8362814c672af61e24d8_ic-bed.svg"
                                                        loading="lazy" alt="Room Icon"/>
                                                    <div>1 Bed</div>
                                                </div>
                                                <div className="room-info-block">
                                                    <img
                                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665d83620edef77fe22b05bb_ic-person.svg"
                                                        loading="lazy" alt="Room Icon"/>
                                                    <div>1 People</div>
                                                </div>
                                            </div>
                                            <h4 className="room-title">Countryside Manor Room</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default SalonDetailPage