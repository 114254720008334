import React from "react";
import {Link} from "react-router-dom";
import Facts from "../components/Facts";
import Reviews from "../components/Reviews";
import HowWeWork from "../components/HowWeWork";
import ListYourSalon from "../components/ListYourSalon";
import {salons} from "../lib/constant";
import Salon from "../components/Salon";
import imgOne from "../media/assets/img-one.jpeg"
import imgTwo from "../media/assets/img-two.webp"
import buttonArrow from "../media/assets/button-arrow.svg"
import whiteButtonArrow from "../media/assets/w-btn-arrow.svg"
import {Skeleton} from "../components/ui/Skeleton";
import {Button} from "../components/ui/Button";
import {SEO} from "../seo/SEO";

function HomePage() {
    return (
        <>
            <SEO
                title="Home | Explore salon rental chairs"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/'
            />
            <div className="page-data mt-3">
                <section className="hero">
                    <div className="hero-wrapper">
                        <div className="hero-top">
                            <div data-w-id="9b97a227-9555-0687-5ea4-de4897d932eb" className="hero-social-data">
                                <div className="hero-social-title">Join our community</div>
                                <div className="social-wrap gap-x-2">
                                    <a href="https://x.com/" target="_blank" className="hero-social w-inline-block" rel="noreferrer">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665ed75a20c6824addf4ec64_ic-twitter.svg"
                                            loading="lazy" alt="Social Icon"/>
                                    </a>
                                    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"
                                       className="hero-social w-inline-block">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665ed75a708080e184eb3a2a_ic-facebook.svg"
                                            loading="lazy" alt="Social Icon"/>
                                    </a>
                                    <a href="https://www.youtube.com/" target="_blank" rel="noreferrer"
                                       className="hero-social w-inline-block">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665ed75aeff73d95b7767bf8_ic-youtube.svg"
                                            loading="lazy" alt="Social Icon"/>
                                    </a>
                                    <a href="https://www.instagram.com/" target="_blank" rel="noreferrer"
                                       className="hero-social w-inline-block">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665ed75a9a0ba904738dcf65_ic-insta.svg"
                                            loading="lazy" alt="Social Icon"/>
                                    </a>
                                </div>
                            </div>
                            {/*<div className="hero-right">*/}
                            {/*    <div className="hero-block">*/}
                            {/*        <h6 className="hero-heading">Beautiful Destinations</h6>*/}
                            {/*        <div className="destination-data">*/}
                            {/*            <div className="client-image-wrap">*/}
                            {/*                <div className="client-img">*/}
                            {/*                    <img*/}
                            {/*                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665acea8423760dc2ad4471a_cta-btn-img-1.jpg"*/}
                            {/*                        loading="lazy" alt="CTA Button Image" className="client-image"/>*/}
                            {/*                </div>*/}
                            {/*                <div className="client-img">*/}
                            {/*                    <img*/}
                            {/*                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665acea811d090998d41a257_cta-btn-img-2.jpg"*/}
                            {/*                        loading="lazy" alt="CTA Button Image" className="client-image"/>*/}
                            {/*                </div>*/}
                            {/*                <div className="client-img">*/}
                            {/*                    <img*/}
                            {/*                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665acea8aa5d8b71d0db4ec9_cta-btn-img-3.jpg"*/}
                            {/*                        loading="lazy" alt="CTA Button Image" className="client-image"/>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="destination-line"></div>*/}
                            {/*            <div>100k+ users have visited our platform</div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="hero-block mobile">*/}
                            {/*        <h6 className="hero-heading">Book with Cruise</h6>*/}
                            {/*        <div className="hero-tag-wrap">*/}
                            {/*            <div className="hero-tag">Luxury Living</div>*/}
                            {/*            <div className="hero-tag">Scenic Views</div>*/}
                            {/*            <div className="hero-tag">Unforgettable Moments</div>*/}
                            {/*            <div className="hero-tag">Spa</div>*/}
                            {/*            <div className="hero-tag">Unique Activities</div>*/}
                            {/*            <div className="hero-tag">Art Tours</div>*/}
                            {/*            <div className="hero-tag">Wine Tasting</div>*/}
                            {/*            <div className="hero-tag">Workshops</div>*/}
                            {/*            <div className="hero-tag">Music</div>*/}
                            {/*            <div className="hero-tag">Memorable Vacations</div>*/}
                            {/*            <div className="hero-tag">Beachfront Stays</div>*/}
                            {/*            <div className="hero-tag">Comfort</div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div data-w-id="42210e79-bbd6-b721-9793-be18fcbfc382" >
                            <p className="hero-text font-medium">
                                You don't own a salon but you want to offer your clients a satisfaction experience?.
                            </p>
                            <h1 className="hero-title">
                                Explore a range of salons offering space at affordable rates.
                            </h1>
                            <div className="hero-button">
                                <Link data-w-id="039cf900-8040-d53d-3aed-58b2c83dd85e" to="/salons" className="button w-inline-block gap-x-2">
                                    <Button className="bg-[#f64f02] rounded-full">
                                        <div>Explore Salons</div>
                                        <div className=" pl-2 white">
                                            <img src={whiteButtonArrow} loading="lazy" alt="Arrow" className="arrow"
                                                style={{transform: `translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                            />
                                        </div>
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="culture">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="culture-wrap">
                            <div className="culture-block">
                                <div data-w-id="4d53c688-22bd-4a6e-80e2-ab4425abcd6a" className="culture-img">
                                    <img src={imgOne} alt="Culture Image" className="culture-image"
                                        loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 46vw, 29vw"
                                        srcSet={`${imgOne} 500w, ${imgOne} 784w`}
                                    />
                                </div>
                                <div className="culture-bottom">
                                    <div id="w-node-_7a7a04f6-da64-f41c-0590-4f48265f898b-774f4a62"
                                         className="culture-fact">
                                        <div className="culture-info">100+</div>
                                        <div>Interested salons in renting out their space</div>
                                    </div>
                                    <img
                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665eb7daa273e66e0cbe50e1_icon-awards.svg"
                                        loading="lazy" alt="Culture Icon" className="culture-icon"/>
                                </div>
                            </div>
                            <div id="w-node-_64ce522a-643b-2eb0-394c-c4cada5f5039-774f4a62" className="culture-block">
                                <div>
                                    <h2 className="culture-title">
                                        We &#x27;re dedicated to create moments of <span className="culture-heading">joy &amp; delight </span>
                                        for every client
                                    </h2>
                                    <p className="single-text">
                                        Costly rentals can make running a salon business stressful hence our platform helps
                                        salon owners to increase their income by renting unused space.
                                    </p>
                                    <div className="button-wrap">
                                        <a data-w-id="3cda146b-d3f8-b5f6-8065-5f26000a64bc" href="/about-us"
                                           className="button w-inline-block">
                                            <div className="secondary-button gap-x-1">
                                                <div>More About us</div>
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                                    loading="lazy"
                                                    style={{transform: `translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                                    alt="Arrow" className="arrow"/>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="culture-bottom">
                                    <div id="w-node-_64ce522a-643b-2eb0-394c-c4cada5f503d-774f4a62"
                                         className="culture-fact">
                                        <div className="culture-info">5</div>
                                        <div>Provinces in South Africa</div>
                                    </div>
                                    <img
                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665eb7dab49c6c406000612f_ic-recognitions.svg"
                                        loading="lazy" alt="Culture Icon" className="culture-icon"/>
                                </div>
                            </div>
                            <div data-w-id="9e4cb09d-23d7-1d68-bb5e-d96e7eaeb28f" className="culture-main-img">
                                <img src={imgTwo} loading="lazy" sizes="(max-width: 991px) 100vw, 29vw"
                                    srcSet={`${imgTwo} 500w, ${imgTwo} 784w`}
                                    alt="Culture Image" className="culture-image"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="rooms">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="sub-heading">
                            <div>
                                <div className="sub-title space">
                                    <img
                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                                        loading="lazy" alt="Title Icon"/>
                                    <div>RENT-A-CHAIR TODAY!</div>
                                </div>
                                <h2 className="section-heading">Explore Salons</h2>
                            </div>
                            <div className="section-button desktop">
                                <a data-w-id="a72fb718-089b-2900-c018-37bf2e6c4225" href="/salons" className="button w-inline-block">
                                    <div className="secondary-button gap-x-1">
                                        <div>View All Salons</div>
                                        <img src={buttonArrow} loading="lazy" alt="Arrow" className="arrow"
                                            style={{transform: `translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                        />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="w-dyn-list">
                            <div role="list" className="room-list w-dyn-items">
                                {salons.slice(0, 6).map((item, key) => (
                                    <div className="flex flex-col space-y-3">
                                        <Skeleton className="h-[280px] w-full rounded-xl"/>
                                        <div className="space-y-2">
                                            <Skeleton className="h-4 w-[250px]"/>
                                            <Skeleton className="h-4 w-[200px]"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="section-button mobile">
                            <a data-w-id="a72fb718-089b-2900-c018-37bf2e6c4242" href="/rooms"
                               className="button w-inline-block">
                                <div className="secondary-button">
                                    <div>View All Rooms</div>
                                </div>
                                <div style={{borderColor: `rgba(0,0,0,0.1)`}} className="button-arrow">
                                    <img
                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                        loading="lazy"
                                        style={{transform: `translate3d(-180%, 180%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                        alt="Arrow" className="hover-arrow"/>
                                    <img
                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                        loading="lazy"
                                        style={{transform: `translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                        alt="Arrow" className="arrow"/>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>
                <HowWeWork />
                {/*<Facts />*/}
                {/*<Reviews />*/}
                <ListYourSalon />
            </div>
        </>
    )
}

export default HomePage