import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {db} from "../../firebase";
import toast from "react-hot-toast";
import {Button} from "../ui/Button";
import {Check, ChevronsUpDown, Pencil} from "lucide-react";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../ui/Form";
import {Input} from "../ui/Input";
import {z} from "zod";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/Popover";
import {cn} from "../../lib/utils";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/Command";
import {categories, provinces} from "../../lib/constant";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/Select";

const formSchema = z.object({
    street: z.string().min(1, {
        message: "Street name is required"
    }),
    suburb: z.string().min(1, {
        message: "Suburb name is required"
    }),
    town: z.string().min(1, {
        message: "City or town name is required"
    }),
    province: z.string().min(1, {
        message: "Province is required"
    }),
    postalCode: z.string().min(1, {
        message: "Post code is required"
    }),
})

export const LocationForm = ({ initialData, salonId }) => {
    const navigate = useNavigate()

    const [isEditing, setIsEditing] = useState(false)
    const toggleEdit = () => setIsEditing((current) => !current)

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            street: initialData?.street || "",
            suburb: initialData?.suburb || "",
            town: initialData?.town || "",
            province: initialData?.province || "",
            postalCode: initialData?.postalCode || "",
        }
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values) => {
        try {
            db.ref('salons').child(`/${salonId}`).update({
                street: values.street,
                suburb: values.suburb,
                town: values.town,
                province: values.province,
                postalCode: values.postalCode
            }).then(() => {
                toast.success("Salon location updated")
                toggleEdit()
                // navigate(0)
            }).catch((err) => {
                toast.error(err.message)
            })
            // await axios.patch(`/api/courses/${salonId}`, values)
            // router.refresh()

        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <div className="mt-6 bg-slate-100 rounded-md p-4">
            <div className="font-medium flex items-center justify-between">
                Salon location
                <Button variant="ghost" onClick={toggleEdit}>
                    {isEditing ? (<>Cancel</>) :
                        (
                            <>
                                <Pencil className="h-4 w-4 mr-2" />
                                Edit location
                            </>
                        )
                    }
                </Button>
            </div>
            {!isEditing && (
                <p className="text-sm mt-2">
                    {initialData.street}, {initialData.suburb}, {initialData.town},
                    {provinces.filter((search) => search.value === initialData.province).map((item) => (
                        <span> {item.label}, </span>
                    ))}
                    {initialData.postalCode}
                </p>
            )}
            {isEditing && (
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                        <div className="space-y-4">
                            <FormField control={form.control} name="street" render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input disabled={isSubmitting} placeholder="Building name and street name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )} />
                            <FormField control={form.control} name="suburb" render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input disabled={isSubmitting} placeholder="Suburb" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )} />
                            <FormField control={form.control} name="town" render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input disabled={isSubmitting} placeholder="City or town" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )} />
                            <div className="flex gap-x-4">
                                <FormField control={form.control} name="province" render={({ field }) => (
                                    <FormItem className="w-full">
                                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select a province" {...field} />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {provinces.map((category) => (
                                                    <SelectItem value={category.value} className="cursor-pointer">
                                                        {category.label}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                                <FormField control={form.control} name="postalCode" render={({ field }) => (
                                    <FormItem className="w-full">
                                        <FormControl>
                                            <Input disabled={isSubmitting} placeholder="PostalCode" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                            </div>
                        </div>

                        <div className="flex items-center gap-x-2">
                            <Button disabled={!isValid || isSubmitting} type="submit">
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            )}
        </div>
    )
}