import React, {useEffect, useState} from "react";
import {Button} from "../ui/Button";
import {Pencil} from "lucide-react";
import {cn, formatPrice} from "../../lib/utils";
import {Form, FormControl, FormDescription, FormField, FormItem, FormMessage} from "../ui/Form";
import {Checkbox} from "../ui/Checkbox";
import {Input} from "../ui/Input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/Select";
import {paymentOption, rentalDuration} from "../../lib/constant";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {db} from "../../firebase";
import toast from "react-hot-toast";
import {z} from "zod";

const formSchema = z.object({
    paymentOption: z.string().min(1, {
        message: "Payment option is required"
    })
});

export const PaymentOptionForm = ({ initialData, salonId}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [priceOption, setPriceOption] = useState("");

    const toggleEdit = () => setIsEditing((current) => !current);

    const router = useNavigate();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            paymentOption: initialData.paymentOption || ""
        },
    });

    const { isSubmitting, isValid } = form.formState;

    const onSubmit = async (values) => {
        try {
            db.ref('salons').child(`/${salonId}`).update({
                paymentOption: values.paymentOption
            }).then(() => {
                toast.success("Payment option updated");
                toggleEdit();
                // router(0);
            }).catch((err) => {
                toast.error(err.message)
            })
        } catch {
            toast.error("Something went wrong");
        }
    }

    return (
        <div className="mt-6 border bg-slate-100 rounded-md p-4">
            <div className="font-medium flex items-center justify-between">
                Payment option
                <Button onClick={toggleEdit} variant="ghost">
                    {isEditing ? (
                        <>Cancel</>
                    ) : (
                        <>
                            <Pencil className="h-4 w-4 mr-2"/>
                            Edit option
                        </>
                    )}
                </Button>
            </div>
            {!isEditing && (
                <p className={cn(
                    "text-sm mt-2",
                    !initialData.paymentOption && "text-slate-500 italic"
                )}>
                    {paymentOption.filter((search) => search.value === initialData.paymentOption).map((item) => (
                        item.label
                    )) || "No payment option"}
                </p>
            )}
            {isEditing && (
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                        <FormField control={form.control} name="paymentOption"
                                   render={({field}) => (
                                       <FormItem className="w-full">
                                           <Select onValueChange={field.onChange} defaultValue={field.value}>
                                               <FormControl>
                                                   <SelectTrigger>
                                                       <SelectValue placeholder="Select payment option" {...field} />
                                                   </SelectTrigger>
                                               </FormControl>
                                               <SelectContent>
                                                   {paymentOption.map((payment) => (
                                                       <SelectItem value={payment.value} className="cursor-pointer">
                                                           {payment.label}
                                                       </SelectItem>
                                                   ))}
                                               </SelectContent>
                                           </Select>
                                           <FormMessage/>
                                       </FormItem>
                                   )}
                        />
                        <div className="flex items-center gap-x-2">
                            <Button disabled={!isValid || isSubmitting} type="submit">
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            )}
        </div>
    )
}