import React from "react";
import {navLink} from "../lib/constant";
import {Link} from "react-router-dom";
import logo from "../media/assets/logo1.jfif"

function Footer() {
    return (
        <section className="footer">
            <div className="footer-top">
                <a href="/" aria-current="page" className="brand w-inline-block w--current">
                    <img src={logo} loading="lazy" alt="Cruise" height={50} width={50} />
                </a>
            </div>
            <div className="footer-center">
                <div className="w-layout-blockcontainer container w-container">
                    <div className="ft-wrap">
                        <div className="ft-left">
                            <div className="ft-sub-title">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66597524ecf21bd82abdbd9f_ft-icon.svg"
                                    loading="lazy" alt="Sub Title Icon"/>
                                <div>Join Our Newsletter</div>
                            </div>
                            <h4 className="ft-title">Keep up with the latest news and updates from Rent-A-Chair.</h4>
                            <div className="contact-form w-form">
                                <form id="wf-form-Contact-Form" name="wf-form-Contact-Form" data-name="Contact Form"
                                      method="get" className="form" data-wf-page-id="660feff9e6770765774f4a62"
                                      data-wf-element-id="23522e29-4d86-1bef-f5e2-03c43e9f1c8f">
                                    <input className="ft-input w-input" maxLength="256" name="Email"
                                           data-name="Email" placeholder="Enter your email" type="email" id="email"
                                           required=""/>
                                    <input type="submit" data-wait="" className="submit-btn w-button"
                                           value="Subscribe Now"/>
                                </form>
                                <div className="success-message w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="error-message w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                        <div className="ft-right">
                            <div className="ft-menu-wrap">
                                <div className="ft-block">
                                    <div className="ft-heading">Pages</div>
                                    <div className="ft-menu">
                                        {navLink.map((item, key) => (
                                            <Link to={item.url} key={key} className="ft-link">
                                                {item.title}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                <div className="ft-block">
                                    <div className="ft-heading">Resources</div>
                                    <div className="ft-menu">
                                        <Link to="/" className="ft-link" aria-disabled="true">Privacy Policy</Link>
                                        <Link to="/" className="ft-link">Terms &amp; Conditions</Link>
                                        <Link to="/" className="ft-link">Changelog</Link>
                                        <Link to="/" className="ft-link">License</Link>
                                    </div>
                                </div>
                                <div className="ft-block">
                                    <div className="ft-heading">Connected</div>
                                    <div className="ft-menu">
                                        <a href="https://www.instagram.com/" target="_blank" rel="noreferrer" className="ft-link">
                                            Instagram
                                        </a>
                                        <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" className="ft-link">
                                            Facebook
                                        </a>
                                        <a href="https://www.youtube.com/" target="_blank" rel="noreferrer" className="ft-link">
                                            YouTube
                                        </a>
                                        <a href="https://x.com/" target="_blank" className="ft-link" rel="noreferrer">
                                            Twitter
                                        </a>
                                    </div>
                                </div>
                                <div id="w-node-_23522e29-4d86-1bef-f5e2-03c43e9f1cc2-3e9f1c80" className="ft-menu-wrap mobile">
                                    <div>
                                        <div className="ft-heading">Email Address</div>
                                        <div className="ft-menu">
                                            <Link to="mailto:info@example.com" className="ft-link">
                                                onlydevsa@gamil.com
                                            </Link>
                                        </div>
                                    </div>
                                    <div id="w-node-_23522e29-4d86-1bef-f5e2-03c43e9f1cc9-3e9f1c80">
                                        <div className="ft-heading">Phone Number</div>
                                        <div className="ft-menu">
                                            <Link to="tel:+(239)555-0108" className="ft-link">
                                                +(27) 69 602 5663
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ft-menu-wrap desktop">
                                <div>
                                    <div className="ft-heading">By Email</div>
                                    <div className="ft-menu">
                                        <Link to="mailto:info@example.com" className="ft-link">
                                            onlydevsa@gamil.com
                                        </Link>
                                    </div>
                                </div>
                                <div id="w-node-_23522e29-4d86-1bef-f5e2-03c43e9f1cd6-3e9f1c80">
                                    <div className="ft-heading">By Phone</div>
                                    <div className="ft-menu">
                                        <Link to="tel:+(239)555-0108" className="ft-link">
                                            +(27) 69 602 5663
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="w-layout-blockcontainer container w-container">
                    <div className="ft-bottom">
                        <div>© {new Date().getFullYear()} Rent-A-Chair. All rights reserved.</div>
                        <div className="flex gap-x-1">
                            Designed and Powered by
                            <Link to="https://onlydev.co.za/" target="_blank" className="ft-bottom-link">
                                 OnlyDev (Pty) Ltd.
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer