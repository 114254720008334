import React, {useState} from "react";
import ListYourSalon from "../components/ListYourSalon";
import Faq from "../components/Faq";
import imgNine from "../media/assets/img-ten.jpg"
import {Button} from "../components/ui/Button";
import {SEO} from "../seo/SEO";

function ContactUsPage() {


    return (
        <div>
            <SEO
                title="Contact Us | Reach out to our team"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/contact-us'
            />
            <div className="page-title">
                <div className="w-layout-blockcontainer container w-container">
                    <div className="sub-title">
                        <img
                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                            loading="lazy" alt="Title Icon"/>
                        <div>Get In Touch</div>
                    </div>
                    <h1 className="main-title">Contact us</h1>
                </div>
            </div>
            <div className="page-data">
                <section className="contact">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="contact-wrap">
                            <div className="contact-left">
                                <div className="contact-form w-form">
                                    <form id="wf-form-Contact-Form-2" name="wf-form-Contact-Form-2"
                                          data-name="Contact Form" method="get"
                                          data-wf-page-id="6659b316bdbf5112e1204fa6"
                                          data-wf-element-id="05b3f5f9-608a-1b6e-9252-a39802a06435">
                                        <div className="form-wrap">
                                            <div>
                                                <label htmlFor="first-name" className="field-label">First name*</label>
                                                <input className="input w-input" maxLength="256" name="First-Name"
                                                       data-name="First Name" placeholder="" type="text" id="first-name"
                                                       required=""/>
                                            </div>
                                            <div>
                                                <label htmlFor="last-name" className="field-label">Last name</label>
                                                <input className="input w-input" maxLength="256" name="Last-Name"
                                                       data-name="Last Name" placeholder="" type="text" id="last-name"/>
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="field-label">Email address*</label>
                                                <input className="input w-input" maxLength="256" name="Email"
                                                       data-name="Email" placeholder="" type="email" id="email"
                                                       required=""/>
                                            </div>
                                            <div>
                                                <label htmlFor="phone-number" className="field-label">Phone
                                                    number</label>
                                                <input className="input w-input" maxLength="256" name="Phone-Number"
                                                       data-name="Phone Number" placeholder="" type="tel"
                                                       id="phone-number"/>
                                            </div>
                                            <div id="w-node-a5808d2f-eb1a-94d1-4a51-de4c3c36aa24-e1204fa6">
                                                <label htmlFor="message" className="field-label">Write your message here
                                                    *</label>
                                                <textarea required="" placeholder="" maxLength="5000" id="message"
                                                          name="Message" data-name="Message"
                                                          className="textarea w-input"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-bottom">
                                            <label className="w-checkbox checkbox-field">
                                                <div
                                                    className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                                                <input type="checkbox" id="checkbox" name="checkbox"
                                                       data-name="Checkbox" required=""
                                                       style={{opacity:0, position:"absolute", zIndex:-1}}/>
                                                <span className="checkbox-label w-form-label" htmlFor="checkbox">
                                                    I here by agree to the <a href="/terms-conditions">Terms &amp; Conditions </a>
                                                    of Cruise
                                                </span>
                                            </label>
                                            <div data-w-id="77f16d0e-d85d-1c81-ab79-fa31b69fb993" className="button">
                                                <Button className="bg-[#f64f02] rounded-full gap-x-1">
                                                    <input type="submit" data-wait="" value="Submit Now"/>
                                                    <img
                                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/66599c3a19bedbe57ff6639f_button-arrow.svg"
                                                        loading="lazy"
                                                        style={{transform: `translate3d(0%, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)`}}
                                                        alt="Arrow" className="arrow"/>
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="success-message w-form-done">
                                        <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="error-message w-form-fail">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                            <div data-w-id="eb42a6e9-2c3d-907d-e0ef-4d25ed9aac31" className="contact-img">
                                <img
                                    src={imgNine}
                                    loading="eager"
                                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 46vw"
                                    srcSet={`${imgNine} 500w, ${imgNine} 800w, ${imgNine} 1080w, ${imgNine} 1236w`}
                                    alt="Contact Image" className="section-image"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="help">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="help-wrap">
                            <div className="help-left">
                                <div className="section-title">
                                    <div className="sub-title space">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                                            loading="lazy" alt="Title Icon"/>
                                        <div>Contact us</div>
                                    </div>
                                    <h2 className="section-heading">We &#x27;re here to help</h2>
                                </div>
                                <p className="help-text">Your satisfaction is our top priority. Our team is available
                                    24/7 to assist with any questions or concerns.</p>
                            </div>
                            <div className="help-right">
                                <div className="help-block">
                                    <div className="help-top">
                                        <div>
                                            <h4 className="help-title">List your Salon</h4>
                                            <div>Secure your spot at our platform today.</div>
                                        </div>
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659bdaaeeac755e48fd886c_ic-reservation.svg"
                                            loading="lazy" alt="Help Icon" className="help-icon"/>
                                    </div>
                                    <div className="help-bottom">
                                        <div className="contact-info">
                                            <div className="mail-icon">
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659beb54e39d4ed1129bc46_ic-call.svg"
                                                    loading="lazy" alt="Mail Icon"/>
                                            </div>
                                            <div>
                                                <div>Phone</div>
                                                <a href="tel:+27696025663" className="contact-link">
                                                    +(27) 69 602 5663
                                                </a>
                                            </div>
                                        </div>
                                        <div className="contact-info">
                                            <div className="mail-icon">
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659beb564d7d2a78303c497_ic-mailid.svg"
                                                    loading="lazy" alt="Mail Icon"/>
                                            </div>
                                            <div>
                                                <div>Email</div>
                                                <a href="mailto:onlydevsa@gmail.com"
                                                   className="contact-link">rentachair@gmail.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="help-block">
                                    <div className="help-top">
                                        <div>
                                            <h4 className="help-title">Support Anytime</h4>
                                            <div>Support team is available 24/7</div>
                                        </div>
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659bda95501d3f434b5820f_ic-support.svg"
                                            loading="lazy" alt="Help Icon" className="help-icon"/>
                                    </div>
                                    <div className="help-bottom">
                                        <div className="contact-info">
                                            <div className="mail-icon">
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659beb54e39d4ed1129bc46_ic-call.svg"
                                                    loading="lazy" alt="Mail Icon"/>
                                            </div>
                                            <div>
                                                <div>Phone</div>
                                                <a href="tel:+27696025663" className="contact-link">
                                                    +27 69 602 5663
                                                </a>
                                            </div>
                                        </div>
                                        <div className="contact-info">
                                            <div className="mail-icon">
                                                <img
                                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659beb564d7d2a78303c497_ic-mailid.svg"
                                                    loading="lazy" alt="Mail Icon"/>
                                            </div>
                                            <div>
                                                <div>Email</div>
                                                <a href="mailto:onlydevsa@gmail.com"
                                                   className="contact-link">rentachair@gmail.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<Faq />*/}
                <ListYourSalon />
            </div>
        </div>
    )
}

export default ContactUsPage