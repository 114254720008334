import React from "react";
import {Button} from "./ui/Button";
import {Pencil, X} from "lucide-react";

export const ImageList = ({ index, data }) => {
    return (
        <div key={index}
             className="flex items-center p-3 w-full bg-sky-100 border-sky-200 border text-sky-700 rounded-md">
            <img src={data.imageUrl} alt=""
                 className="h-16 w-16 object-cover mr-2 flex-shrink-0"/>
            {/*<p className="text-xs line-clamp-1">*/}
            {/*    {data.imageId}*/}
            {/*</p>*/}
            <div className="ml-auto flex flex-col">
                <Button onClick={() => {}} size="sm" variant="ghost"
                        className="ml-auto hover:opacity-75 transition">
                    <Pencil className="h-4 w-4"/>
                </Button>
                <Button onClick={() => {}} size="sm" variant="ghost"
                        className="ml-auto hover:opacity-75 transition">
                    <X className="h-4 w-4"/>
                </Button>
            </div>
        </div>
    )
}