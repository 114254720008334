import React from "react";
import imgFour from "../media/assets/img-four.jpg"
import imgFive from "../media/assets/img-five.jpg"
import {Link} from "react-router-dom";
import {Button} from "./ui/Button";
import ListButton from "./ListButton";

function ListYourSalon() {
    return (
        <section className="cta">
            <div className="w-layout-blockcontainer container w-container">
                <div className="cta-wrap">
                    <div className="cta-data">
                        <img
                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659c8a383ccbaf0d3da6141_ic-cta.svg"
                            loading="lazy" alt="CTA Icon" className="cta-icon"/>
                        <h2 className="cta-title">INCREASE YOUR INCOME BY RENTING A CHAIR IN YOUR SALON.</h2>
                        <p className="cta-text">
                            Rent a chair in your salon when it is not busy and start increasing your income by adding another
                            income source. Get clients by listing your salon!
                        </p>
                        <div className="cta-button-wrap">
                            <ListButton />
                        </div>
                    </div>
                    <div data-w-id="7caedcc7-9825-5de2-2086-fe469616e9e3" className="cta-img">
                        <img src={imgFive} loading="lazy"
                             sizes="(max-width: 767px) 100vw, (max-width: 991px) 230px, 348px"
                             srcSet={`${imgFive} 500w, ${imgFive} 696w`} alt="CTA Image" className="cta-image"/>
                    </div>
                    <div data-w-id="7caedcc7-9825-5de2-2086-fe469616e9e5" className="cta-thumb-img">
                        <img src={imgFour} loading="lazy" alt="CTA Image" className="cta-thumb-image"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ListYourSalon