import React, {useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {auth, db} from "../firebase";
import {Button} from "../components/ui/Button";
import {SEO} from "../seo/SEO";

function SignUpPage() {
    const navigate = useNavigate()

    const [data, setData] = useState({
        firstName: "",
        surname: "",
        email: "",
        password: "",
        confirmPassword: "",
        terms: ""
    })

    const handleChange = name => event => {
        const value = name === "terms" ? event.target.checked : event.target.value
        setData({ ...data, [name]: value })

    }

    const createAccount = (e) => {
        e.preventDefault()

        if (data.password === data.confirmPassword) {
            auth.createUserWithEmailAndPassword(data.email, data.password).then((authUser) => {
                if (authUser) {
                    db.ref('account').child(`/${authUser.user.uid}`).update({
                        firstName: data.firstName,
                        surname: data.surname,
                        email: data.email,
                        userId: authUser.user.uid,
                        dateJoined: new Date().getTime()
                    }).then(() => {
                        toast.success("Account has been created successfully")
                        navigate("log-in")
                    }).catch((err) => {
                        toast.error(err.message)
                    })
                } else {
                    toast("Something went wrong")
                }
            })
        } else {
            toast.error("Password do not match")
        }
    }

    return (
        <div>
            <SEO
                title="Sign Up | Create an account"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/sign-up'
            />
            <div className="page-title">
                <div className="w-layout-blockcontainer container w-container">
                    <h1 className="main-title center">Sign Up</h1>
                </div>
            </div>
            <div className="page-data">
                <div className="w-users-usersignupformwrapper login-form">
                    <div className="w-users-userformsuccessstate success-state w-form-success">
                        <div className="w-users-userformheader">
                            <h4>Account activated</h4>
                        </div>
                        <p className="body-small">Your account was created successfully. You will be redirected
                            shortly.</p>
                        <div data-wf-user-form-redirect="">
                            <a href="/" className="w-inline-block">If nothing happens, click here.</a>
                        </div>
                    </div>
                    <form onSubmit={createAccount}>
                        <div className="form-field">
                            <div className="flex gap-x-2">
                                <div className="w-full">
                                    <label htmlFor="firstName" className="field-label">First Name</label>
                                    <input className="input w-input" maxLength="256" name="firstName" placeholder=""
                                           type="text" id="wf-sign-up-name" required="true" value={data.firstName}
                                           onChange={handleChange("firstName")}/>
                                </div>
                                <div className="w-full">
                                    <label htmlFor="surname" className="field-label">Surname</label>
                                    <input className="input w-input" maxLength="256" name="surname" placeholder=""
                                           type="text" id="wf-sign-up-name" required="true" value={data.surname}
                                           onChange={handleChange("surname")}/>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="field-label">Email Address</label>
                                <input maxLength="256" placeholder="" name="email" id="wf-sign-up-email"
                                       className="input w-input" type="email" autoComplete="username" required="true"
                                       value={data.email} onChange={handleChange("email")}/>
                            </div>
                            <div>
                                <label htmlFor="password" className="field-label">Password</label>
                                <input maxLength="256" placeholder="" name="password" id="wf-sign-up-password"
                                       className="input w-input" type="password" required="true" value={data.password}
                                       onChange={handleChange("password")} />
                            </div>
                            <div>
                                <label htmlFor="confirmPassword" className="field-label">Confirm Password</label>
                                <input maxLength="256" placeholder="" name="confirmPassword" id="wf-sign-up-password"
                                       className="input w-input" type="password" required="true" value={data.confirmPassword}
                                       onChange={handleChange("confirmPassword")} />
                            </div>
                        </div>
                        <div className="agreement-wrap">
                            <label className="w-checkbox">
                                <input className="w-checkbox-input" name="terms" placeholder="" type="checkbox"
                                       id="wf-sign-up-accept-privacy" required="true" value={data.terms}
                                       onChange={handleChange("terms")}/>
                                <span className="w-form-label">
                                    By creating an account, I agree to the <Link to="/privacy-policy" className="w-inline-block pr-1">privacy policy</Link>
                                    and <Link to="/terms-conditions" className="w-inline-block">terms of service</Link>
                                </span>
                            </label>
                            <label className="w-checkbox">
                                <input className="w-checkbox-input" name="" placeholder="" type="checkbox"
                                       id="wf-sign-up-accept-communications"/>
                                <span className="w-form-label">I consent to receive marketing emails.</span>
                            </label>
                        </div>
                        <Button className="bg-[#f64f02] w-full rounded-full my-8">
                            <input type="submit" data-wait="Please wait..."
                                   className="w-users-userformbutton primary-button hover w-button" value="Sign Up"/>
                        </Button>
                        <div className="w-users-userformfooter">
                            <span>Already have an account?</span>
                            <a href="/log-in" className="w-inline-block">Log In</a>
                        </div>
                    </form>
                    <div tabIndex="-1"
                         className="w-users-usersignupverificationmessage sign-up-verification w-form-verification"
                         data-wf-user-form-verification="true">
                        <div className="w-users-userformheader">
                            <h4>Verification Required</h4>
                        </div>
                        <p>Account verification required. Please check your email to find your unique verification
                            link.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpPage