import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {db} from "../../firebase";
import toast from "react-hot-toast";
import {Button} from "../ui/Button";
import {Mail, Pencil, Phone} from "lucide-react";
import {provinces} from "../../lib/constant";
import {Form, FormControl, FormField, FormItem, FormMessage} from "../ui/Form";
import {Input} from "../ui/Input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/Select";
import {z} from "zod";

const formSchema = z.object({
    phoneNumber: z.string().min(1, {
        message: "Phone number is required"
    }),
    email: z.string().min(1, {
        message: "Email address is required"
    }),
})

export const ContactForm = ({ initialData, salonId }) => {
    const [isEditing, setIsEditing] = useState(false)
    const toggleEdit = () => setIsEditing((current) => !current)

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            phoneNumber: initialData?.phoneNumber || "",
            email: initialData?.email || "",
        }
    })

    const {isSubmitting, isValid} = form.formState

    const onSubmit = async (values) => {
        try {
            db.ref('salons').child(`/${salonId}`).update({
                phoneNumber: values.phoneNumber,
                email: values.email,
            }).then(() => {
                toast.success("Salon contact details updated")
                toggleEdit()
                // navigate(0)
            }).catch((err) => {
                toast.error(err.message)
            })
            // await axios.patch(`/api/courses/${salonId}`, values)
            // router.refresh()

        } catch {
            toast.error("Something went wrong")
        }
    }

    return (
        <div className="mt-6 bg-slate-100 rounded-md p-4">
            <div className="font-medium flex items-center justify-between">
                Salon contact details
                <Button variant="ghost" onClick={toggleEdit}>
                    {isEditing ? (<>Cancel</>) :
                        (
                            <>
                                <Pencil className="h-4 w-4 mr-2"/>
                                Edit contacts
                            </>
                        )
                    }
                </Button>
            </div>
            {!isEditing && (
                <p className="text-sm mt-2 flex flex-col gap-y-2">
                    <div className="flex gap-x-2 items-center">
                        <Phone className="h-4 w-4" />
                        <span>{initialData.phoneNumber}</span>
                    </div>
                    <div className="flex gap-x-2 items-center">
                        <Mail className="h-4 w-4" />
                        <span>{initialData.email}</span>
                    </div>
                </p>
            )}
            {isEditing && (
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
                        <div className="space-y-4">
                            <FormField control={form.control} name="phoneNumber" render={({field}) => (
                                <FormItem>
                                    <FormControl>
                                        <Input disabled={isSubmitting}
                                               placeholder="Phone number" {...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}/>
                            <FormField control={form.control} name="email" render={({field}) => (
                                <FormItem>
                                    <FormControl>
                                        <Input disabled={isSubmitting} placeholder="Email address" {...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}/>
                        </div>
                        <div className="flex items-center gap-x-2">
                            <Button disabled={!isValid || isSubmitting} type="submit">
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            )}
        </div>
    )
}