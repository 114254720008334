import React from "react";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "./ui/Accordion";
import {faq} from "../lib/constant";

function Faq() {
    return (
        <section className="faq">
            <div className="w-layout-blockcontainer container w-container">
                <div className="section-title">
                    <div className="sub-title space">
                        <img
                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                            loading="lazy" alt="Title Icon"/>
                        <div>Faq’s</div>
                    </div>
                    <h2 className="section-heading">Have any questions?</h2>
                </div>
                <div className="faq-wrap">
                    <div data-w-id="f47f759e-b007-f076-5da2-9a29ad583236" className="faq-img">
                        <img
                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659c242486e76af8bbcaf64_faq.jpg"
                            loading="lazy"
                            sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 39vw"
                            srcSet="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659c242486e76af8bbcaf64_faq-p-500.jpg 500w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659c242486e76af8bbcaf64_faq-p-800.jpg 800w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659c242486e76af8bbcaf64_faq.jpg 1060w"
                            alt="FAQ Image" className="section-image"/>
                        <div className="on-scroll"></div>
                    </div>
                    <div className="faq-data">
                        <div data-w-id="f47f759e-b007-f076-5da2-9a29ad583239"
                             className={`faq-info w-dropdown`} style={{border: "none"}}>
                            <div className="que w-dropdown-toggle">
                                <Accordion type="single" collapsible className="w-full">
                                    {faq.map((item, key) => (
                                        <AccordionItem value={key + 1} key={key}>
                                            <AccordionTrigger>
                                                <h5 className="que-text">{item.title}</h5>
                                            </AccordionTrigger>
                                            <AccordionContent className="ans w-dropdown-list">
                                                <div className="ans-box">
                                                    <p className="ans-text text-wrap">{item.description}</p>
                                                </div>
                                            </AccordionContent>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq