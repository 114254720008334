import React from "react";
import HowWeWork from "../components/HowWeWork";
import ListYourSalon from "../components/ListYourSalon";
import imgSix from "../media/assets/img-six.webp"
import imgSeven from "../media/assets/img-seven.webp"
import imgThree from "../media/assets/img-three.png"
import imgEight from "../media/assets/img-eight.png"
import {Button} from "../components/ui/Button";
import {Link} from "react-router-dom";
import {SEO} from "../seo/SEO";
import ListButton from "../components/ListButton";

function AboutUsPage() {
    return (
        <div>
            <SEO
                title="About Us | Learn what inspire us to start the rent a chair platform"
                description="Explore chairs from salons and barbers at an affordable rate/"
                canonicalURL='https://rentchair.co.za/about-us'
            />
            <div className="page-title">
                <div className="w-layout-blockcontainer container w-container">
                    <div className="sub-title">
                        <img
                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                            loading="lazy" alt="Title Icon"/>
                        <div>About us</div>
                    </div>
                    <h1 className="main-title">Who We Are</h1>
                </div>
            </div>
            <div className="page-data">
                <section className="about">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="about-wrap">
                            <div className="about-data">
                                <div data-w-id="eed470e9-3e7a-fac1-8178-f0e1535d5c0e" className="about-img mobile">
                                    <img
                                        src={imgSix}
                                        loading="eager" sizes="(max-width: 767px) 100vw, 46vw"
                                        srcSet={`${imgSix} 500w, ${imgSix} 800w, ${imgSix} 1080w, ${imgSix} 1236w`}
                                        alt="About Image" className="about-image"/>
                                </div>
                                <div>
                                    <p className="single-text">
                                        We are beauty experts with also a keen interest in property. Owing to high rental
                                        fees which are eating into salon owners pockets we having been seeking for a solution.
                                        Rent-A-Chair is an ultimate solution as it not only help salon owners with rentals but
                                        works as an additional revenue stream.
                                    </p>
                                    <div className="about-bottom">
                                        <div className="about-tag">
                                            <div>100+ Salon Owners Interested</div>
                                            <img
                                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665da2c624f4466669cdd058_ic-beds.svg"
                                                loading="lazy" alt="About Icon"/>
                                        </div>
                                        <div className="about-tag">
                                            <div>5 Provinces with Accessibility</div>
                                            <img
                                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665da2c5b65df8adb298ec3f_ic-awards.svg"
                                                loading="lazy" alt="About Icon"/>
                                        </div>
                                        <div className="about-tag">
                                            <div>100+ Satisfaction</div>
                                            <img
                                                src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665da2c5b1aa32ad39b3384b_ic-satisfaction.svg"
                                                loading="lazy" alt="About Icon"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-data">
                                <div>
                                    <h3 className="about-title">
                                        Rent-A-Chair is the ultimate solution to generate more revenue from your salon.
                                    </h3>
                                    <p className="single-text">
                                        Set your own affordable rate or payment option and rules that needs to followed when
                                        a lessee request for a chair. The lessor has control over the term in which they are
                                        renting their chair - it can be an hour, day, week, month or 3 months depending on
                                        their ultimate goal.
                                    </p>
                                </div>
                                <div data-w-id="0c0f1c35-373e-45c5-a834-9133cee925f5" className="about-img">
                                    <img src={imgSeven}
                                        loading="eager" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 46vw"
                                        srcSet={`${imgSeven} 500w, ${imgSeven} 800w, ${imgSeven} 1080w, ${imgSeven} 1236w`}
                                        alt="About Image" className="about-image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <HowWeWork />
                <section className="impact">
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="section-title">
                            <div className="sub-title space">
                                <img
                                    src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/6659b299906d5895eeb0229b_ic-subtitle.svg"
                                    loading="lazy" alt="Title Icon"/>
                                <div>Our Impact</div>
                            </div>
                            <h2 className="section-heading">Why Choose Cruise</h2>
                        </div>
                        <div className="impact-wrap">
                            <div className="impact-top">
                                <div className="impact-block">
                                    <img
                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665db386faa2c019686b8ede_ic-staff.svg"
                                        loading="lazy" alt="Impact Icon" className="impact-icon"/>
                                    <div>
                                        <h4 className="impact-title">Memorable Experiences</h4>
                                        <p className="single-text">
                                            You don't own a salon by you want your client to experience a memorable experience?
                                            Rent a chair from any of the salons listed on our platform.
                                        </p>
                                    </div>
                                </div>
                                <div id="w-node-_0bdedffd-f27c-247f-4d09-3f5f8937960a-acfaf583" className="impact-img">
                                    <img
                                        src={imgThree}
                                        loading="lazy" sizes="(max-width: 767px) 100vw, 31vw"
                                        srcSet={`${imgThree} 500w, ${imgThree} 832w`}
                                        alt="Impact Image" className="impact-image"/>
                                </div>
                                <div id="w-node-_42c988b3-eefc-38eb-97ce-8fef7bb8912f-acfaf583"
                                     className="impact-block">
                                    <img
                                        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665db4e49bee43795bc0894b_ic-experience.svg"
                                        loading="lazy" alt="Impact Icon" className="impact-icon"/>
                                    <div>
                                        <h4 className="impact-title">Verified Salons</h4>
                                        <p className="single-text">
                                            After registering on our platform, our team go through an extensive research
                                            to validate that the information sent match the real situation of the salon.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="impact-bottom">
                                <div data-delay="4000" data-animation="slide" className="impact-slider w-slider"
                                     data-autoplay="true" data-easing="ease" data-hide-arrows="false"
                                     data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3"
                                     data-duration="1000" data-infinite="true"
                                     id="w-node-_671e3b63-d4d4-0672-f346-d0dbbe0a1182-acfaf583">
                                    <div className="w-slider-mask">
                                        <div className="w-slide">
                                            <img
                                                src={imgEight}
                                                loading="lazy"
                                                sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 31vw"
                                                srcSet={`${imgEight} 500w, ${imgEight} 800w, ${imgEight} 856w`}
                                                alt="Impact Image" className="impact-slider-image"/>
                                        </div>
                                        {/*<div className="w-slide">*/}
                                        {/*    <img*/}
                                        {/*        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc5a8d8bec5336227f5_impact-02.jpg"*/}
                                        {/*        loading="lazy"*/}
                                        {/*        sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 31vw"*/}
                                        {/*        srcSet="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc5a8d8bec5336227f5_impact-02-p-500.jpg 500w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc5a8d8bec5336227f5_impact-02-p-800.jpg 800w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc5a8d8bec5336227f5_impact-02.jpg 856w"*/}
                                        {/*        alt="Impact Image" className="impact-slider-image"/>*/}
                                        {/*</div>*/}
                                        {/*<div className="w-slide">*/}
                                        {/*    <img*/}
                                        {/*        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc511117907f9512fb7_impact-03.jpg"*/}
                                        {/*        loading="lazy"*/}
                                        {/*        sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 31vw"*/}
                                        {/*        srcSet="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc511117907f9512fb7_impact-03-p-500.jpg 500w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc511117907f9512fb7_impact-03-p-800.jpg 800w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc511117907f9512fb7_impact-03.jpg 856w"*/}
                                        {/*        alt="Imapact Image" className="impact-slider-image"/>*/}
                                        {/*</div>*/}
                                        {/*<div className="w-slide">*/}
                                        {/*    <img*/}
                                        {/*        src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc5cd7eb463d15e5cde_impact-04.jpg"*/}
                                        {/*        loading="lazy"*/}
                                        {/*        sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 31vw"*/}
                                        {/*        srcSet="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc5cd7eb463d15e5cde_impact-04-p-500.jpg 500w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc5cd7eb463d15e5cde_impact-04-p-800.jpg 800w, https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbbc5cd7eb463d15e5cde_impact-04.jpg 856w"*/}
                                        {/*        alt="Impact Image" className="impact-slider-image"/>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div data-w-id="671e3b63-d4d4-0672-f346-d0dbbe0a1186"
                                         className="impact-left-arrow w-slider-arrow-left">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665dbb5e145aefab24965c13_impact-left-arrow.svg"
                                            loading="lazy" style={{filter: "invert(0%)"}} alt="Arrow" className="arrow"/>
                                    </div>
                                    <div data-w-id="671e3b63-d4d4-0672-f346-d0dbbe0a1188"
                                         className="impact-right-arrow w-slider-arrow-right">
                                        <img
                                            src="https://cdn.prod.website-files.com/660feff9e6770765774f4a4f/665db78ae40c823a40cc84f4_impact-r-arrow.svg"
                                            loading="lazy" style={{filter: "invert(0%)"}} alt="Arrow" className="arrow"/>
                                    </div>
                                    <div className="d-none w-slider-nav w-round w-num"></div>
                                </div>
                                <div className="impact-block">
                                    <h4 className="impact-heading">Be Part of Something Special</h4>
                                    <div className="impact-info">
                                        <p className="single-text">
                                            Whether you are a salon owner or a freelancer hairdresser, Rent-A-Chair is here
                                            to bring convenience and share memorable experience to clients.
                                        </p>
                                        <ListButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<Reviews />*/}
                {/*<Members />*/}
                {/*<Blog />*/}
                {/*<Faq />*/}
                <ListYourSalon/>
            </div>
        </div>
    )
}

export default AboutUsPage