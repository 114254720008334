import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useConfettiStore} from "../hooks/useConfettiStore";
import toast from "react-hot-toast";
import {Button} from "./ui/Button";
import {Trash} from "lucide-react";
import {ConfirmModal} from "../modals/ConfirmModal";
import {auth, db} from "../firebase";

export const Actions = ({disabled, salonId, isPublished, salon}) => {
    const navigate = useNavigate();
    const confetti = useConfettiStore();
    const [isLoading, setIsLoading] = useState(false);

    const onClick = async () => {
        try {
            setIsLoading(true);

            if (isPublished) {
                db.ref('salons').child(`/${salonId}`).update({
                    isPublished: false
                }).then(() => {
                    db.ref("mySalons").child(`/${auth?.currentUser?.uid}/${salonId}`).update({
                        isPublished: false
                    }).then(() => {
                        toast.success("Salon unpublished");
                    }).catch((err) => {
                        toast.error(err.message)
                    })
                }).catch((err) => {
                    toast.error(err.message)
                })
            } else {
                db.ref('salons').child(`/${salonId}`).update({
                    isPublished: true
                }).then(() => {
                    db.ref("mySalons").child(`/${auth?.currentUser?.uid}/${salonId}`).update({
                        salonName: salon.salonName,
                        description: salon.description,
                        phoneNumber: salon.phoneNumber,
                        email: salon.email,
                        street: salon.street,
                        suburb: salon.suburb,
                        town: salon.town,
                        province: salon.province,
                        postalCode: salon.postalCode,
                        category: salon.category,
                        extraServices: salon.extraServices,
                        paymentOption: salon.paymentOption,
                        price: salon.price,
                        rentalDuration: salon.rentalDuration,
                        images: salon.images,
                        salonId: salon.salonId,
                        isPublished: true
                    }).then(() => {
                        toast.success("Salon published");
                        confetti.onOpen();
                        navigate('/account/salons')
                    }).catch((err) => {
                        toast.error(err.message)
                    })
                }).catch((err) => {
                    toast.error(err.message)
                })
            }
        } catch {
            toast.error("Something went wrong");
        } finally {
            setIsLoading(false);
        }
    }

    const onDelete = async () => {
        try {
            setIsLoading(true);

            // await axios.delete(`/api/courses/${salonId}`);

            toast.success("Salon deleted");
            // router.refresh();
            navigate(`/account/salons`);
        } catch {
            toast.error("Something went wrong");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="flex items-center gap-x-2">
            <Button
                onClick={onClick}
                disabled={disabled || isLoading}
                variant="outline"
                size="sm"
            >
                {isPublished ? "Unpublish Salon" : "Publish Salon"}
            </Button>
            <ConfirmModal onConfirm={onDelete}>
                <Button size="sm" disabled={isLoading}>
                    <Trash className="h-4 w-4" />
                </Button>
            </ConfirmModal>
        </div>
    )
}